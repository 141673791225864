import Header from '../../home-page/Header';

import { Col, Container, Row } from 'react-bootstrap';
import { Footer } from '../../home-page/Footer';
import Hero from '../../home-page/Hero';
import { QRGenerateBox } from './QRGenerateBox';
import { useEffect } from 'react';

export function QRShortenPage() {
  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <Hero />
      <Container>
        <Row className='center justify-content-center align-items-center'>
          <Col xs={9} md={9}>
            <QRGenerateBox />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}
