import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Card, Button, Pagination, Col } from 'react-bootstrap';
import logo from './logo.svg';
import { HiLink } from 'react-icons/hi';
import { MdModeEditOutline } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { ProfileLinkEditModal } from './ProfileLinkEditModal';
import { ProfileLinkDeleteModal } from './ProfileLinkDeleteModal';
import { stat } from 'fs';
import { useNavigate } from 'react-router';
import { Loading } from '../Loading';
import { ErrorTextModal } from '../popup/ErrorTextModal';
import { SuccessTextModal } from '../popup/SuccessTextModal';

export function ProfileLinks() {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [projectDeleted, setProjectDeleted] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const [project, setProject] = useState();
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [state, setState] = useState({
    data: [],
    limit: 10,
    offset: 0,
  });
  const navigate = useNavigate();
  const fetchProjects = async (limit: number, offset: number) => {
    setLoadingVisible(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/urls?email=mhqasrawi.mhq@gmail.com&limit=${limit}&offset=${offset}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setLoadingVisible(false);
      if (response.ok) {
        const result = await response.json();
        console.log('result', result);
        setTotalCount(result.totalCount);
        setState((prev) => ({
          offset: prev.offset + result.data.length,
          limit: prev.limit,
          data: result.data,
        }));
      } else {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  };

  useEffect(() => {
    fetchProjects(state.limit, state.offset);
  }, [projectDeleted]);
  const handlePageChange = (pageNumber: number) => {
    setState((prev) => ({ ...prev, activePage: pageNumber }));
    setActivePage(pageNumber);
    if (pageNumber === 1) {
      fetchProjects(state.limit, 0);
    } else {
      fetchProjects(state.limit, pageNumber * 10 - 10);
    }
  };

  function handleDeleteProject(shortId: string) {
    state.data = state.data.filter(
      (project: any) => project.shortid !== shortId
    );
    setProjectDeleted(!projectDeleted);
    state.limit = 10;
    state.offset = 0;
  }
  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Container
          id='theta-projects'
          fluid
          className='our-projects mt-5 y-flex justify-content-center align-content-center center'
          style={{ backgroundColor: 'transparent' }}
        >
          <Row className='d-flex justify-content-center align-content-center center mx-2'>
            <SuccessTextModal
              successText={successText}
              show={successText.length > 0}
              onHide={() => setSuccessText('')}
            />
          </Row>
          <Row className='d-flex justify-content-center align-content-center center mx-2'>
            {state && state.data && state.data.length > 0 ? (
              state.data.map((project: any, index) => (
                <Card
                  key={index}
                  style={{ width: '18rem' }}
                  className='mx-3 my-3 project-card'
                >
                  <Card.Header style={{ backgroundColor: 'transparent' }}>
                    <p>
                      <HiLink
                        size={30}
                        style={{ color: '#135D66' }}
                        className='mx-2'
                      />
                      {project.name}
                    </p>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>Orginal Url: </p>
                      {project.orginalUrl}
                    </Card.Text>
                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>active: </p>
                      {project.active ? 'Yes' : 'No'}
                    </Card.Text>

                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>Created at: </p>
                      {new Date(project.createdAt).toISOString().split('T')[0]}
                    </Card.Text>
                    <Card.Text className='project-text'>
                      <p style={{ fontWeight: 'bold' }}>Shorten Url:</p>
                      <a
                        target='_blank'
                        href={project.shortenedUrl}
                        rel='noreferrer'
                      >
                        {project.shortenedUrl}{' '}
                      </a>
                    </Card.Text>
                    <Button
                      variant='success'
                      onClick={() => {
                        handleShow();
                        setProject(project);
                        setSelectedStatus(
                          project.active ? 'Active' : 'Inactive'
                        );
                      }}
                    >
                      <MdModeEditOutline size={30} className='mx-1' />
                      Edit
                    </Button>
                    <Button
                      variant='danger'
                      className='mx-md-2 my-2'
                      onClick={() => {
                        handleShowDeleteModal();
                        setProject(project);
                      }}
                    >
                      <MdDelete size={30} className='mx-1' />
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <Row className='d-flex align-items-center'>
                <Col className='d-flex justify-content-end'>
                  <p style={{ color: '#2C4E80' }}>
                    You have not shortened any URL yet ,{' '}
                  </p>
                </Col>
                <Col className='d-flex justify-content-start align-items-center center'>
                  <Button
                    variant='success'
                    onClick={() => navigate('/URLShortenPage')}
                  >
                    Shorten URL
                  </Button>
                </Col>
              </Row>
            )}
          </Row>

          <ProfileLinkEditModal
            handleShow={handleShow}
            handleClose={handleClose}
            show={show}
            project={project}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            setSuccessText={setSuccessText}
            setErrorText={setErrorText}
          />

          <ProfileLinkDeleteModal
            handleShowDeleteModal={handleShowDeleteModal}
            handleCloseDeleteModal={handleCloseDeleteModal}
            showDeleteModal={showDeleteModal}
            project={project}
            setSuccessText={setSuccessText}
            setErrorText={setErrorText}
            handleDeleteProject={handleDeleteProject}
          />
          <Pagination className='px-4'>
            {Array.from({ length: Math.ceil(totalCount / 10) }, (_, index) => (
              <Pagination.Item
                onClick={() => handlePageChange(index + 1)}
                key={index + 1}
                active={index + 1 === activePage}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>

          <ErrorTextModal
            errorText={errorText}
            show={errorText.length > 0}
            onHide={() => setErrorText('')}
          />
        </Container>
      )}
    </>
  );
}
