import React, { useContext, useEffect, useRef, useState } from 'react';
import { Nav, Navbar, ListGroup, ListGroupItem } from 'react-bootstrap';
import './../../App.css';
import { AiOutlineLink, AiOutlineQrcode } from 'react-icons/ai';
import { FaLink } from 'react-icons/fa6';
import { IoAnalytics } from 'react-icons/io5';
import { CiLogout } from 'react-icons/ci';
import { MdAccountCircle } from 'react-icons/md';

import logo from './../logo.svg';
import { useLogout } from '../auth/Logout';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaKey } from 'react-icons/fa';
import { PiBracketsCurlyBold } from 'react-icons/pi';

export function Sidebar({
  onItemClick,
}: {
  onItemClick: (item: string) => void;
}) {
  const logout = useLogout();
  const trimyCtx = useContext(TrimyLinkContext);
  async function handleLogout() {
    await logout();
  }
  useEffect(() => {}, [trimyCtx]);

  return (
    <>
      <div className='sidebar mt-5 px-0' style={{ backgroundColor: '#f4f6fa' }}>
        <div
          className='sidebar-content'
          style={{ overflowY: 'auto', maxHeight: '100vh' }}
        >
          {' '}
          {/* Add maxHeight for scrolling */}
          <Navbar
            className='flex-column'
            style={{ overflowY: 'auto', height: '100vh' }}
          >
            <Navbar.Brand className='mt-5'>
              <img
                src={logo}
                alt='profile logo'
                className='border rounded-circle'
                style={{ width: '100px', height: '100px' }}
              />
            </Navbar.Brand>
            <Nav.Link
              className='mt-3'
              style={{ color: '#074173', fontWeight: 'bold', fontSize: '1rem' }}
            >
              Tier: {localStorage.getItem('tier')}
            </Nav.Link>
            <Navbar.Collapse id='responsive-navbar-nav'>
              <ListGroup>
                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item '
                  onClick={() => onItemClick('info')}
                  style={{ cursor: 'pointer' }}
                >
                  <MdAccountCircle className='mx-1' size={32} />
                  Account Info
                </ListGroupItem>
                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  onClick={() => onItemClick('manageSubscription')}
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className='mx-1'
                    size='1x'
                  />
                  Manage Subscription
                </ListGroupItem>
                <hr className='w-50' />
                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  onClick={() => {
                    onItemClick('links');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <AiOutlineLink className='mx-1' size={32} />
                  Links
                </ListGroupItem>

                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  onClick={() => {
                    onItemClick('bulk-shortening');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <PiBracketsCurlyBold className='mx-1' size={32} />
                  Bulk Shortening
                </ListGroupItem>

                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onItemClick('qr');
                  }}
                >
                  <AiOutlineQrcode className='mx-1' size={32} />
                  QR Codes
                </ListGroupItem>
                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onItemClick('linkInBio');
                  }}
                >
                  <FaLink className='mx-1' />
                  Link in Bio
                </ListGroupItem>
                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onItemClick('analytics');
                  }}
                >
                  <IoAnalytics className='mx-1' size={32} />
                  Analytics
                </ListGroupItem>

                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onItemClick('api-keys');
                  }}
                >
                  <FaKey className='mx-1' size={24} />
                  API Keys
                </ListGroupItem>

                <ListGroupItem
                  className='border-0 mt-3 bg-transparent sidebar-item'
                  style={{ cursor: 'pointer' }}
                  onClick={handleLogout}
                >
                  <CiLogout className='mx-1' size={32} />
                  Logout
                </ListGroupItem>
              </ListGroup>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
}
