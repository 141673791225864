import React, { useState } from 'react';
import Header from '../../home-page/Header';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Footer } from '../../home-page/Footer';
import { URLGenerateBox } from './URLGenerateBox';
import Hero from '../../home-page/Hero';

export function URLShortenPage() {
  return (
    <>
      <Header />
      <Hero />
      <Container>
        <Row className='center justify-content-center align-items-center'>
          <Col xs={9} md={9}>
            <URLGenerateBox />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
