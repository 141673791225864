import { set } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

export function TopThreeAnalytics({
  errorText,
  setErrorText,
  from,
  to,
}: {
  errorText: string;
  setErrorText: any;
  from: any;
  to: any;
}) {
  const [topThreeUrl, setTopThreeUrl] = useState<any>([]);
  const [topThreeQr, setTopThreeQr] = useState<any>([]);
  const [bioTopThree, setBioTopThree] = useState<any>([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    fetchTopThree('url');
    fetchTopThree('qr');
    fetchTopThree('bio');
  }, [from, to]);

  async function fetchTopThree(type: string) {
    setLoadingVisible(true);
    const requestId = Date.now().toString() + ' - top-three';
    try {
      let url;
      if (type.toLowerCase() === 'url')
        if (from && to) {
          url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/urls/top-three?from=${from}&to=${to}`;
        } else {
          url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/urls/top-three`;
        }
      else if (type.toLowerCase() === 'qr')
        if (from && to) {
          url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/qrs/top-three?from=${from}&to=${to}`;
        } else {
          url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/qrs/top-three`;
        }
      else if (type.toLowerCase() === 'bio') {
        if (from && to) {
          url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/bio/top-three?from=${from}&to=${to}`;
        } else {
          url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/bio/top-three`;
        }
      } else {
        setErrorText('Invalid type');
        setShowError(true);
        throw Error('');
      }
      if (url) {
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
      });
      setLoadingVisible(false);

      if (response.ok) {
        const data = await response.json();
        if (type === 'url') setTopThreeUrl(data);
        else if (type === 'qr') setTopThreeQr(data);
        else if (type === 'bio') {
          setBioTopThree(data);
        }
        setErrorText('');
        setShowError(false);
      } else {
        if (response.status === 400) {
          const data = await response.json();
          const error = data.message;
          setErrorText(error);
          setShowError(true);
        } else if (response.status !== 403) {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId}`
      );
      setShowError(true);
    }
  }

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Row className='g-4 mt-5'>
          <Col xs={12} sm={12} md={6} lg={4}>
            <Card
              key='top3-urls'
              style={{
                width: '18rem',
                color: 'white',
                backgroundColor: '#2A4978',
              }}
              className='mb-2'
            >
              <Card.Header style={{ fontWeight: 'bold' }}>
                Top 3 Performing URL's
              </Card.Header>
              <Card.Body>
                <Table borderless style={{ backgroundColor: 'transparent' }}>
                  <thead
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <tr>
                      <th
                        className='text-white '
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Name
                      </th>
                      <th
                        className='text-center text-white '
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Visits Count
                      </th>
                    </tr>
                  </thead>

                  {topThreeUrl &&
                    Array.isArray(topThreeUrl) &&
                    topThreeUrl.length > 0 && (
                      <tbody>
                        {topThreeUrl.map((url: any, index: number) => (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: 'transparent',
                                paddingLeft: '0px',
                              }}
                              className='text-white'
                            >
                              {url.name}
                            </td>
                            <td
                              className='text-center text-white'
                              style={{
                                backgroundColor: 'transparent',
                                paddingLeft: '0px',
                              }}
                            >
                              {url.count}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={6} lg={4}>
            <Card
              key='top3-qrs'
              style={{
                width: '18rem',
                color: 'white',
                backgroundColor: '#2A4978',
              }}
              className='mb-2'
            >
              <Card.Header style={{ fontWeight: 'bold' }}>
                Top 3 Performing QR's
              </Card.Header>
              <Card.Body>
                <Table borderless style={{ backgroundColor: 'transparent' }}>
                  <thead
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <tr>
                      <th
                        className='text-white '
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Name
                      </th>
                      <th
                        className='text-center text-white '
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Visits Count
                      </th>
                    </tr>
                  </thead>

                  {topThreeQr &&
                    Array.isArray(topThreeQr) &&
                    topThreeQr.length > 0 && (
                      <tbody>
                        {topThreeQr.map((qr: any, index: number) => (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: 'transparent',
                                paddingLeft: '0px',
                              }}
                              className='text-white'
                            >
                              {qr.name}
                            </td>
                            <td
                              className='text-center text-white'
                              style={{
                                backgroundColor: 'transparent',
                                paddingLeft: '0px',
                              }}
                            >
                              {qr.count}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} sm={12} md={6} lg={4}>
            <Card
              key='top3-bio-urls'
              style={{
                width: '18rem',
                color: 'white',
                backgroundColor: '#2A4978',
              }}
              className='mb-2'
            >
              <Card.Header style={{ fontWeight: 'bold' }}>
                Top 3 Performing Bio Links
              </Card.Header>
              <Card.Body>
                <Table borderless style={{ backgroundColor: 'transparent' }}>
                  <thead
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <tr>
                      <th
                        className='text-white '
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Name
                      </th>
                      <th
                        className='text-center text-white '
                        style={{
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        }}
                      >
                        Visits Count
                      </th>
                    </tr>
                  </thead>

                  {bioTopThree &&
                    Array.isArray(bioTopThree) &&
                    bioTopThree.length > 0 && (
                      <tbody>
                        {bioTopThree.map((link: any, index: number) => (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: 'transparent',
                                paddingLeft: '0px',
                              }}
                              className='text-white'
                            >
                              {link.title}
                            </td>
                            <td
                              className='text-center text-white'
                              style={{
                                backgroundColor: 'transparent',
                                paddingLeft: '0px',
                              }}
                            >
                              {link.count}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
