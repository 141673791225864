import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { SuccessTextModal } from '../popup/SuccessTextModal';
import { ErrorTextModal } from '../popup/ErrorTextModal';

export function BulkShortening() {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [bulkFiles, setBulkFiles] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const requestId = Date.now().toString();
  async function uploadBulkFile() {
    try {
      const formData = new FormData();
      formData.append('bulkShortenFile', selectedFile);
      formData.append('bulkShortenFilename', selectedFile.name);
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/bulk-shortening`,
        {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
            'request-id': requestId,
          },
          body: formData,
        }
      );
      setSelectedFile(null);
      if (fileInputRef)
        if (fileInputRef.current) fileInputRef.current.value = '';

      const data = await response.json();
      if (response.ok) {
        setSuccessText(data.message);
      } else {
        setErrorText(
          data.message ||
            data.error ||
            `error while bulk shortening URLs  --- Reference Number: ${requestId}`
        );
      }
    } catch (error) {}
  }

  async function fetchBulkFiles() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/bulk-shortening`,
        {
          method: 'GET',
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
            'request-id': requestId,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setBulkFiles(data);
      }
    } catch (error) {}
  }
  useEffect(() => {
    fetchBulkFiles();
  }, [successText, errorText]);
  return (
    <Container className='mt-5'>
      <h1>Bulk Shortening</h1>

      <Row>
        <Col>
          <Form>
            <Form.Group controlId='formFile' className='mb-3'>
              <Form.Label>Upload CSV</Form.Label>
              <Form.Control
                type='file'
                accept='.csv'
                ref={fileInputRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target.files?.[0] || null;
                  setSelectedFile(file);
                }}
              />
            </Form.Group>
            <Button
              variant='primary'
              onClick={uploadBulkFile}
              disabled={!selectedFile}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>

      {bulkFiles && bulkFiles.length > 0 && (
        <Row className='mt-5'>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Number of Records</th>
                <th>Upload Time</th>
              </tr>
            </thead>
            <tbody>
              {bulkFiles.map((file, index) => (
                <tr key={index}>
                  <td>{file.fileName}</td>
                  <td>{file.numberOfRecords}</td>
                  <td>{file.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      )}

      <SuccessTextModal
        successText={successText}
        show={successText.length > 0}
        onHide={() => setSuccessText('')}
      />

      <ErrorTextModal
        errorText={errorText}
        show={errorText.length > 0}
        onHide={() => setErrorText('')}
      />
    </Container>
  );
}
