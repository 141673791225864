import { Dropdown, FormControl, Form, Row } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

export function AnalyticsLinkInBioDropDown({
  errorText,
  setErrorText,
}: {
  errorText: string;
  setErrorText: any;
}) {
  const [items, setItems] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    fetchLinks();
  }, []);

  async function fetchLinks(): Promise<any[]> {
    setLoadingVisible(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/link-in-bio`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setLoadingVisible(false);
      if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else if (response.status !== 404) {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      } else {
        const data = await response.json();
        setErrorText('');
        setShowError(false);
        if (data) {
          setItems(data);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId}`
      );
      setShowError(true);
      return [];
    }
    return [];
  }
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleSelect = (selectedItem: any) => {
    setSelectedItems((prevState: any) => {
      if (prevState.includes(selectedItem)) {
        return prevState.filter((item: any) => item !== selectedItem);
      } else {
        return [...prevState, selectedItem];
      }
    });
  };

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <div className='d-flex center align-items-center justify-content-center  mt-3 mx-sm-5 mx-3'>
          <Dropdown onToggle={isOpen ? handleClose : handleOpen}>
            <Dropdown.Toggle variant='outline-success' id='dropdown-basic'>
              Link In Bio
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <FormControl
                autoFocus
                placeholder='Type a name to filter...'
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />

              {items.map((item: any) => (
                <>
                  <Dropdown.Header>{item.bioUsername}</Dropdown.Header>
                  {item.bioLinks.map((link: any) => (
                    <>
                      {link.title
                        .toUpperCase()
                        .includes(search.toUpperCase()) && (
                        <Dropdown.Item
                          key={link.title}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelect(link.title);
                          }}
                        >
                          <Form.Check
                            type='checkbox'
                            label={link.title}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleSelect(link.title);
                            }}
                            checked={selectedItems.includes(link.title)}
                          />
                        </Dropdown.Item>
                      )}
                    </>
                  ))}
                  <Dropdown.Divider />
                </>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
