import { MutatingDots } from 'react-loader-spinner';
export function Loading({ visible }: { visible: boolean }) {
  return (
    <>
      {visible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <MutatingDots
            visible={visible}
            height='100'
            width='100'
            color='#7BA7E7'
            secondaryColor='#3E78CE'
            radius='12.5'
            ariaLabel='mutating-dots-loading'
          />
        </div>
      )}
    </>
  );
}
