import React, { useEffect, useState } from 'react';
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Alert,
  Card,
} from 'react-bootstrap';
import './../../ForgotPasswordPage.css';
import { useLocation, useNavigate } from 'react-router';
import { ErrorTextModal } from '../popup/ErrorTextModal';
import { SuccessTextModal } from '../popup/SuccessTextModal';

export function ForgotPassword() {
  const [email, setEmail] = useState(); // Replace with actual email logic
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [errorText, setErrorText] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successText, setSuccessText] = useState('');

  useEffect(() => {
    const state = location.state;
    console.log(state, showPasswordForm);
    if (state && state.email) {
      setEmail(state.email);
    } else if (!showPasswordForm) navigate('/');
  }, []);
  const handleOtpChange = (element: any, index: any) => {
    if (/^\d*$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);

      // Move focus to next input box
      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (element: any, index: any) => {
    if (
      element.key === 'Backspace' &&
      !otp[index] &&
      element.target.previousSibling
    ) {
      element.target.previousSibling.focus();
    }
  };

  async function verifyOtp() {
    const requestId = Date.now().toString();
    try {
      const otpValue = otp.join('');
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/verify-otp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ email, otp: otpValue }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setShowPasswordForm(true);
      } else {
        setErrorText(data.message);
      }
    } catch (error) {
      setErrorText(
        `Something went wrong. Please try again later. --- Reference Number: ${requestId}`
      );
    }
  }

  async function handleChangePassword() {
    const requestId = Date.now().toString();
    console.log('otp', otp);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({
            email,
            otp: otp.join(''),
            password,
            confirmPassword,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSuccessText(
          'Password reset successfully. Please login with your new password.'
        );
      } else {
        setErrorText(data.message);
      }
    } catch (err) {
      setErrorText(
        `Something went wrong. Please try again later. --- Reference Number: ${requestId}`
      );
    }
  }

  return (
    <Container className='d-flex flex-column justify-content-center align-items-center min-vh-100'>
      {!showPasswordForm && email && (
        <Row className='w-100'>
          <Col
            md={{ span: 6, offset: 3 }}
            className='p-4 shadow rounded bg-white'
          >
            <h2 className='text-center mb-4'>Forgot Password</h2>
            <Form>
              <Card className='mb-4'>
                <Card.Body>
                  <Card.Title className='text-center'>
                    Enter OTP to Reset Password
                  </Card.Title>
                  <Card.Text className='text-center'>
                    for email <strong>{email}</strong>
                  </Card.Text>
                </Card.Body>
              </Card>

              <Form.Group controlId='formOtp'>
                <Form.Label>Enter OTP</Form.Label>
                <div className='otp-input-container'>
                  {otp.map((digit, index) => (
                    <Form.Control
                      key={index}
                      type='text'
                      className='otp-input'
                      value={digit}
                      onChange={(e) => handleOtpChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                    />
                  ))}
                </div>
              </Form.Group>

              <Button
                variant='primary'
                className='w-100 mt-3'
                onClick={verifyOtp}
              >
                Verify OTP
              </Button>
            </Form>
          </Col>
        </Row>
      )}

      {showPasswordForm && (
        <Container>
          <h2 className='mt-4'>Reset Password</h2>
          <Form>
            <Form.Group controlId='formPassword' className='mb-3'>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter new password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId='formConfirmPassword' className='mb-3'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm new password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant='primary' onClick={handleChangePassword}>
              Reset Password
            </Button>
          </Form>
        </Container>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={errorText.length > 0}
        onHide={() => {
          setErrorText('');
        }}
      />

      <SuccessTextModal
        successText={successText}
        show={successText.length > 0}
        onHide={() => {
          setSuccessText('');
          navigate('/');
        }}
      />
    </Container>
  );
}
