import { Button, Container } from 'react-bootstrap';
import './../../App.css';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';

export function GetStartedHero({ resultRef }: any) {
  const trimyLinkCtx = useContext(TrimyLinkContext);

  useEffect(() => {}, [trimyLinkCtx]);

  function handleClick(): void {
    resultRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className='w-100  custom-bg-color align-items-center center justify-content-center d-flex flex-column px-3 py-3 ml-5 mr-5 mx-3'>
      <h1 className='mt-5'>
        Get Started and let TrimyLink do the Magic for you
      </h1>
      <Button
        className='mb-5'
        variant='primary'
        size='lg'
        onClick={handleClick}
      >
        {trimyLinkCtx.getStartedGenerateButtonText}
      </Button>
    </div>
  );
}
