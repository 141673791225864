import React from 'react';
// import './App.css';
import { Root } from './components/Root';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TrimyLinkProvider } from './store/TrimyLinkContext';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { BiSupport } from 'react-icons/bi';
import { MdContactSupport } from 'react-icons/md';
import {
  Container,
  Button,
  Link,
  darkColors,
} from 'react-floating-action-button';
function App() {
  return (
    <TrimyLinkProvider>
      <Root />
      <Container>
        <Link
          href='/contact-us'
          tooltip='Contact Us'
          styles={{ backgroundColor: darkColors.lightBlue }}
        >
          <BiSupport color='#EEEEEE' size={32} />
        </Link>
        <Button
          tooltip='Need Help?'
          styles={{ backgroundColor: darkColors.lightBlue }}
        >
          {' '}
          <MdContactSupport color='#EEEEEE' size={48} />
        </Button>
      </Container>
    </TrimyLinkProvider>
  );
}

export default App;
