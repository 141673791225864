import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Loading } from '../../Loading';
import './../../../App.css';
import { Button } from 'react-bootstrap';
import { ErrorTextModal } from '../../popup/ErrorTextModal';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
};

export function SummaryChart({
  errorText,
  setErrorText,
  from,
  setFrom,
  to,
  setTo,
  analyticsAllowed,
  setAnalyticsAllowed,
}: {
  errorText: string;
  setErrorText: any;
  from: any;
  setFrom: any;
  to: any;
  setTo: any;
  analyticsAllowed: any;
  setAnalyticsAllowed: any;
}) {
  const [chartData, setChartData] = useState<any>({});
  const [loadingVisible, setLoadingVisible] = useState(true);
  const [showError, setShowError] = useState(false);

  async function fetchSummaryAnalytics() {
    console.log('fetchSummaryAnalytics');
    const requestId = Date.now().toString() + ' - Summary';
    let url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/summary`;
    if (from && to) {
      url = `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/analytics/summary?from=${from}&to=${to}`;
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          'request-id': requestId,
        },
      });
      if (response.status === 403) {
        setAnalyticsAllowed(false);
      } else if (response.ok) {
        setAnalyticsAllowed(true);
        const jsonData = await response.json();
        const labels: string[] = [];
        const counts: number[] = [];
        jsonData.map((item: any) => {
          labels.push(`${item.start} - ${item.end}`);
          counts.push(item.count);
        });
        const data = {
          labels,
          datasets: [
            {
              label: 'Clicks Summary',
              data: counts,
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
          ],
        };
        console.log(data);
        setChartData(data);
        setLoadingVisible(false);
        setErrorText('');
        setShowError(false);
      } else {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference zzzzzzNumber: ${requestId}`
          );
          setShowError(true);
        }
        setLoadingVisible(false);
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId}`
      );
      setShowError(true);
    }
  }

  useEffect(() => {
    console.log('fetchSummaryAnalytics1');
    setLoadingVisible(true);
    fetchSummaryAnalytics();
  }, [from, to]);
  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        analyticsAllowed &&
        chartData &&
        chartData.labels &&
        chartData.datasets && (
          <>
            <div className='d-flex center align-items-center justify-content-center  mt-3   w-100 chart-container'>
              <Line data={chartData} options={options} className='w-100' />
            </div>
          </>
        )
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
