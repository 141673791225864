import { Button, Card, Col, Row } from 'react-bootstrap';
import { Footer } from './home-page/Footer';
import Header from './home-page/Header';
import imgBlog from './link-blog.png';
import qrBio from './qr-blog.jpg';

import apiBlog from './api-blog.jpg';
import shopifyLogo from './shopify-blog.jpg';

import { useNavigate } from 'react-router';
import WhyTrimyLinkIsTheUltimateSolutionforYourOnlinePresence from './Untitleddesign2.png';
import UnlockTheSecretsOfQRCodeMarketing from './UnlockTheSecretsOfQRCodeMarketing.svg';
const blogs = [
  {
    order: 9,
    title:
      'Unlocking Your Online Potential with Trimylink.io Shorten URL Service',
    shortDescription: `Revolutionize your online presence with trimylink.io Shorten URL service – transform lengthy URLs into sleek, shareable links that captivate your audience and drive results. Craft a cohesive and compelling online identity with customizable shortened URLs, strengthening brand recognition and fostering deeper connections with your audience.`,
    description: `
    <Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Introduction:</b>
        </h3>
        <br></br>Welcome to the era of streamlined online presence, where
        every click counts and every character matters. In the bustling
        digital landscape, standing out amidst the noise can be daunting,
        but fear not at trimylink.io, we're here to empower you with the
        tools you need to thrive. Today, we delve into the transformative
        power of our Shorten URL service and how it can revolutionize your
        online journey.
      </p>
    </Row>

    <Row>
      <p>
        <h3>
          <b> Why Choose Trimylink.io: </b>
        </h3>
        <br></br>
        At trimylink.io, we understand the importance of making a lasting
        impression in the digital realm. That's why our Shorten URL service
        isn't just about condensing links – it's about amplifying your voice
        and maximizing your impact. With our intuitive platform, you can
        effortlessly transform lengthy, convoluted URLs into sleek,
        shareable links that captivate your audience and drive results.
      </p>
    </Row>

    <Row>
      <p>
        <h3>
          <b> Crafting Your Online Identity: </b>
        </h3>
        <br></br>
        Your online presence is more than just a collection of links – it's
        a reflection of your brand, your values, and your aspirations. With
        trimylink.io, you have the power to craft a cohesive and compelling
        online identity that resonates with your audience. Our customizable
        shortened URLs enable you to infuse your brand personality into
        every link, strengthening brand recognition and fostering deeper
        connections with your audience.
      </p>
    </Row>

    <Row>
      <p>
        <h3>
          <b> Measuring Success, One Click at a Time: </b>
        </h3>
        <br></br>
        In the dynamic world of digital marketing, data is king. That's why
        trimylink.io provides robust analytics tools that empower you to
        track the performance of your shortened URLs with precision and
        insight. From click-through rates to geographic distribution, our
        analytics dashboard offers a comprehensive view of your URL
        performance, allowing you to optimize your strategies and drive
        tangible results.
      </p>
    </Row>

    <Row>
      <p>
        <h3>
          <b>Join the Trimylink.io Revolution: </b>
        </h3>
        <br></br>
        Join the Trimylink.io Revolution: In conclusion, trimylink.io isn't
        just a URL shortening service – it's a catalyst for your online
        success. With our user-friendly platform, customizable links, and
        powerful analytics tools, you have everything you need to unlock
        your online potential and achieve your goals. Join the trimylink.io
        revolution today, and elevate your online presence to new heights.
      </p>
    </Row>
  </Row>`,
    image: imgBlog,
  },
  {
    order: 8,
    title: `Transforming Your Marketing Strategy with Trimylink.io QR Code Generator`,
    shortDescription: `Unlock the potential of QR codes with trimylink.io Generator service – effortlessly create custom codes to engage your audience and drive results. Elevate your brand presence and maximize impact with customizable QR codes and robust analytics tools.`,
    description: `
   
   <Row className='mt-5'> <p >In today's fast-paced digital landscape, capturing your audience's attention is more challenging than ever. Fortunately, at trimylink.io, we're dedicated to equipping you with the tools you need to stand out and succeed. Join us as we explore the unparalleled potential of our QR Code Generator service and discover how it can revolutionize your marketing strategy.
</p></Row>

<Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>
Why Choose Trimylink.io:
</b>
        </h3>
        <br></br>
At trimylink.io, we're committed to innovation and excellence. Our QR Code Generator isn't just about creating codes – it's about unlocking new possibilities and driving meaningful engagement. With our user-friendly platform, you can effortlessly generate custom QR codes that captivate your audience and inspire action, all while reinforcing your brand identity and credibility.

<Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>The Power of QR Codes:</b>
</h3>
<br></br>
QR codes have emerged as a powerful tool for bridging the gap between the physical and digital worlds. From product packaging to promotional materials, QR codes offer a seamless and interactive way to connect with your audience and deliver value. Whether you're sharing exclusive content, collecting customer feedback, or driving online sales, QR codes enable you to engage with your audience on a deeper level and drive results.

<Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Elevating Your Brand Presence:</b>
</h3>
<br></br>
Your brand is more than just a logo – it's an experience. With trimylink.io QR Code Generator, you have the opportunity to elevate your brand presence and create memorable interactions with your audience. Customize your QR codes with your brand colors, logo, and messaging to ensure consistency and authenticity across all touchpoints. By infusing your brand identity into every code, you reinforce brand recognition and foster trust and loyalty among your audience.

<Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Measuring Impact, Maximizing Results:</b>
</h3>
<br></br>
In the world of marketing, data is invaluable. That's why trimylink.io provides robust analytics tools that empower you to track the performance of your QR codes with precision and insight. Monitor scan rates, geographic distribution, and user demographics to gain a deeper understanding of your audience and optimize your campaigns for maximum impact. With actionable insights at your fingertips, you can refine your strategies and drive tangible results.

<Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Join the Trimylink.io Revolution:</b>
</h3>
<br></br>
In conclusion, trimylink.io QR Code Generator is more than just a tool  it's a catalyst for your marketing success. With our intuitive platform, customizable codes, and powerful analytics tools, you have everything you need to elevate your brand presence, engage your audience, and drive meaningful results. Join the trimylink.io revolution today, and take your marketing strategy to new heights.
        
`,
    image: qrBio,
  },
  {
    order: 7,
    title:
      'Elevate Your Social Media Strategy with Trimylink.io Link in Bio Solution',
    shortDescription: `Transform your social media presence with trimylink.io Link in Bio solution – consolidate links, enhance user experience, and drive meaningful engagement. Elevate your social media strategy with customizable landing pages and comprehensive analytics tools for actionable insights.`,
    description: `
    <Row><p>In the dynamic world of social media, first impressions are everything. That's why at trimylink.io, we're dedicated to helping you make a lasting impact and stand out from the crowd. Join us as we explore the transformative potential of our Link in Bio solution and discover how it can revolutionize your social media strategy.</p></Row>

    <Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>
    Why Choose Trimylink.io:</b>
    </h3>
    <br></br>
    At trimylink.io, we understand the importance of making every link count. Our Link in Bio solution isn't just about organizing your links – it's about optimizing your social media presence and driving meaningful engagement. With our intuitive platform, you can create a personalized landing page that showcases your brand, highlights your content, and encourages action, all while simplifying the user experience for your audience.
    
    <Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b> Streamlining Your Social Media Presence:</b>
          </h3>
          <br></br>
    Your social media bio is your digital storefront – it's the first thing your audience sees, and it sets the tone for their entire experience. With trimylink.io Link in Bio solution, you can transform your bio into a powerful marketing tool that drives traffic, boosts engagement, and converts followers into customers. Instead of cluttering your bio with multiple links, consolidate them into a single, cohesive landing page that directs users to your most important content and offerings.
    
    <Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Enhancing User Experience:</b>
          </h3>
          <br></br>
    In today's fast-paced digital landscape, convenience is key. That's why trimylink.io Link in Bio solution is designed with the user experience in mind. Our customizable landing pages are optimized for mobile devices, ensuring a seamless browsing experience for your audience, whether they're accessing your content on their smartphone, tablet, or desktop. By simplifying the navigation process and reducing friction, you can increase engagement and encourage users to explore more of your content.
    
    <Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Driving Results with Actionable Insights:</b>
          </h3>
          <br></br>
    In the world of social media marketing, data is essential for making informed decisions and driving results. That's why trimylink.io provides comprehensive analytics tools that empower you to track the performance of your Link in Bio landing page with precision and insight. Monitor click-through rates, user demographics, and traffic sources to gain a deeper understanding of your audience and optimize your social media strategy for maximum impact.
    
    <Row>
    <Row className='mt-5'>
      <p>
        <h3>
          <b>Join the Trimylink.io Revolution:</b>
          </h3>
          <br></br>
    In conclusion, trimylink.io Link in Bio solution is more than just a tool it's a game-changer for your social media strategy. With our intuitive platform, customizable landing pages, and powerful analytics tools, you have everything you need to elevate your social media presence, engage your audience, and drive meaningful results. Join the trimylink.io revolution today, and take your social media strategy to new heights.
    
   
    `,
    image: imgBlog,
  },
  {
    order: 6,
    image: imgBlog,
    title: 'The Ultimate Guide to Link Management for Businesses',
    shortDescription: `Why Link Management is Crucial for Your Business Growth`,
    description: `<Row className='mt-5'>
    In today's digital landscape, effective link management is essential for any business looking to expand its online presence, drive traffic, and increase conversions. Links are the pathways that connect your audience to your brand, whether through social media bios, email marketing campaigns, or QR codes on product packaging.
    </Row>
    
    <Row className='mt-5'>
    
    <p>However, the challenges of managing numerous long, unwieldy URLs can quickly become overwhelming.  Disorganized links not only look unprofessional but also make it difficult to track their performance and optimize your marketing efforts.</p>

</Row>


<Row className='mt-5'>
<p ><b className='mt-5'>TrimyLink: Your Solution for Streamlined Link Management</b></p>
<p>TrimyLink is a powerful link management platform designed to simplify your digital life.  With features like custom branded short links, QR code generation, and detailed analytics, TrimyLink empowers businesses to create a seamless and effective online experience.</p>


<p><b>Key benefits of using TrimyLink for your business:</b></p>

<ul>
<li>Improved Brand Recognition: Create short, branded links that reinforce your brand identity and make a lasting impression.</li>
<li>Enhanced Tracking and Analytics: Gain valuable insights into link performance to optimize your marketing strategies.</li>
<li>Increased Engagement: Use QR codes to bridge the gap between offline and online channels, driving traffic and engagement.</li>
<li>Simplified Organization: Manage all your links in one centralized dashboard with customizable folders and tags.</li>
</ul>

<p><b>How Businesses Are Leveraging TrimyLink for Success</b></p>
<ul>
<li>E-commerce: Drive more sales by creating memorable, branded short links for product pages and promotional campaigns.</li>
<li>Marketing Agencies: Track the performance of marketing initiatives and refine strategies for maximum ROI.</li>
<li>Social Media Influencers: Share branded links in their bios to drive traffic and track audience engagement.</li>
<li>Event Planners: Use QR codes on promotional materials to provide easy access to registration pages and event details.</li>

</ul>
</Row>



<Row className='mt-5'>
<p><b>Unlock the Full Potential of Your Links</b></p>
<p>TrimyLink offers a variety of plans to suit the needs of any business, from our free plan for basic link shortening to our Standard plan with advanced features and priority support.</p>
<p>Ready to see how TrimyLink can transform your link management? </p><a href='https://trimylink.io/signup'>Sign up for a free trial today!</a>


</Row>
`,
  },
  {
    order: 5,
    title: 'Creative Ways to Use QR Codes to Boost Your Marketing Efforts',
    shortDescription: `QR codes (Quick Response codes) have experienced a resurgence in popularity thanks to their versatility and ease of use. They offer a simple way to connect offline and online experiences, providing a seamless journey for your audience.`,
    description: `
    <Row className='mt-5'>
    <p><b>Here are 5 innovative ways to leverage QR codes for your marketing campaigns:</b></p>
    <ol>
    <li>Interactive Product Packaging: Add QR codes to product labels, packaging, or even in-store displays to provide customers with additional information, such as product demos, recipes, or exclusive offers.</li>
    <li>Offline-to-Online Marketing: Use QR codes on print materials (flyers, brochures, business cards) to direct people to your website, social media pages, or landing pages, increasing online engagement.</li>
    <li>Event Promotions: Make your events more interactive with QR codes on tickets, banners, or promotional materials. Attendees can scan the codes to access event schedules, speaker bios, or even participate in contests.</li>
    <li>Customer Feedback: Gather valuable feedback from customers by placing QR codes on receipts, packaging, or feedback forms. This helps you improve your products and services based on direct customer input.</li>
    <li>Exclusive Deals and Promotions: Create QR codes that unlock special discounts, promotions, or bonus content when scanned. This incentivizes customers to interact with your brand and can drive repeat business.</li>

    </Row>
    
    
    <Row className='mt-5'>
<p></b>TrimyLink Simplifies QR Code Creation and Management</b></p>
<p>With TrimyLink, you can easily generate custom QR codes, track scan data, and integrate them with your shortened links. Our intuitive platform makes it simple to incorporate QR codes into your marketing strategy.</p>
<p>Ready to harness the power of QR codes? Try TrimyLink's QR code generator today and elevate your marketing campaigns.</p>
    </Row>
    `,
    image: qrBio,
  },
  {
    order: 4,
    title:
      'Unleashing the Power of Links: Introducing the TrimyLink API for Seamless Integration',
    shortDesciption:
      'Discover how the TrimyLink API empowers developers and marketers to automate link management, create custom workflows, and unlock new possibilities for their brands.',
    description: `
      <Row className='mt-5'><p>In today's fast-paced digital world, efficient link management is crucial for businesses, marketers, and developers alike. Long, cumbersome URLs can be difficult to track, share, and brand effectively. That's where TrimyLink comes in, offering a comprehensive solution for shortening, customizing, and analyzing links.
</p></Row>
<Row className='mt-3'><p>Now, we're thrilled to introduce the TrimyLink API, a powerful tool that enables seamless integration with your existing systems and applications.
</p></Row>
<Row className='mt-3'><p><b>Why Integrate with the TrimyLink API?</b></p>
<p>The TrimyLink API opens a world of possibilities for those looking to streamline their link management and supercharge their marketing efforts. Here are just a few of the benefits:</p>
<ul className='mt-3'>
<li><b>Automation:</b> Automate the creation of short links and QR codes for your products, blog posts, social media posts, or any other online content.
<li><b>Customization:</b> Tailor your links to match your brand's unique identity with custom domains, slugs, and more.
</li>
<li><b>Enhanced Tracking:</b> Gain deeper insights into link performance with detailed analytics on clicks, geographic data, and referral sources.
</li>
<li><b>Flexible Workflows:</b> Integrate TrimyLink into your existing systems, tools, and workflows to create a seamless link management experience.
</li>
</ul>
</Row>
<Row className='mt-3'><p><b>Who Can Benefit from the TrimyLink API?</b></p>
<p>The TrimyLink API is designed for a wide range of users, including:</p>
<ul>
<li><b>Developers:</b> Easily integrate TrimyLink's functionality into your own applications and platforms.
</li>
<li><b>Marketers:</b> Automate link creation, track campaigns, and optimize your link strategy for maximum impact.
</li>
<li><b>E-commerce Businesses:</b> Streamline product link management and enhance customer experiences with branded short links and QR codes.
</li>
<li><b>Content Creators:</b> Easily share and track your content with short, custom links.
</li>
<li>Anyone looking to simplify link management!</li>
</ul>
</Row>
<Row className='mt-3'><p><b>Getting Started with the TrimyLink API</b></p>
<p>We've made it easy to get started with the TrimyLink API:</p>
<ol>
<li><b>Sign Up:</b> If you don't have an account yet, sign up for a TrimyLink account.
</li>
<li><b>Upgrade:</b> Upgrade to a paid plan to access the API. New users can take advantage of our special offer and get their first month of TrimyLink Standard FREE using the code <b>WELCOME100</b> at checkout.
</li>
<li><b>Get Your API Key:</b> Access your unique API key in your account settings.
</li>
<li><b>Explore the Documentation:</b> Our comprehensive API documentation provides detailed instructions, code examples, and best practices to guide you through the integration process.
</li>
</ol>
</Row>

<Row className='mt-5'>
<p><b>Take Your Link Management to the Next Level</b></p>
<p>The TrimyLink API is a powerful tool that can unlock new levels of efficiency and effectiveness in your link management strategy. Whether you're looking to automate tedious tasks, gain deeper insights into your audience, or create a more seamless user experience, our API has you covered.</p>

</Row>
`,
    image: apiBlog,
  },
  {
    order: 3,
    title: `Boost Your Shopify Store's Sales with Smarter Link Management: A Guide for E-commerce Owners`,
    shortDescription: `Learn how to optimize your Shopify store's marketing with TrimyLink. Shorten links, create QR codes, and track performance to drive more traffic and sales.`,
    description: `
    <Row>
    <h3<Introduction:</h3>

<p>In the competitive world of e-commerce, every click counts. As a Shopify store owner, you understand the importance of maximizing your marketing efforts and driving traffic to your product pages. But are you making the most of your links?</p>
    
    </Row>

    <Row className='mt-5'>
<p>Long, unwieldy product URLs can be a turnoff for potential customers and make it difficult to track the effectiveness of your marketing campaigns. That's where TrimyLink comes in.</p>

    </Row>

    <Row className='mt-5'>
    <h3>What is TrimyLink, and How Can It Help My Shopify Store?</h3>

    <p>TrimyLink is a powerful link management platform designed to simplify and enhance your online presence. It offers a suite of features that can directly benefit your Shopify store:</p>


    <ol>
    <li>Shorten Product URLs: Transform long, complex product URLs into short, branded links that are easier to share and remember. This can improve click-through rates and make your marketing materials look more professional.</li>
    <li>Create QR Codes for Products: Generate eye-catching QR codes for your product pages. Print them on product packaging, flyers, or in-store displays to drive offline traffic directly to your online store.</li>
    <li>Track Link Performance: Gain valuable insights into which marketing channels are driving the most traffic and sales. TrimyLink's analytics dashboard allows you to track clicks, conversions, and other key metrics, helping you optimize your marketing strategies.</li>
    <li>Link in Bio Optimization: Create a beautiful, branded link-in-bio page for your social media profiles, showcasing your best products and promotions.</li>


    </ol>
    </Row>

    <Row className='mt-5'>

    <h3>Why TrimyLink is a Must-Have for Shopify Store Owners:</h3>

    <li>Increased Click-Through Rates: Short, branded links are more likely to be clicked than long, generic ones.</li>
    <li>Improved Brand Recognition: Custom short links reinforce your brand identity.</li>
    <li>Enhanced Marketing Analytics: Track the success of your campaigns and make data-driven decisions.</li>
    <li>Better Customer Experience: QR codes make it easy for customers to access your products.</li>
    <li>Omnichannel Marketing: Bridge the gap between your online and offline marketing efforts.</li>


    </Row>

    <Row className='mt-5'>
    <h3>Getting Started with TrimyLink:</h3>
<ol>
    <li>Sign up for a free TrimyLink account.</li>
    <li>Connect your Shopify store.</li>
    <li>Start shortening and customizing your product URLs.</li>
    <li>Generate QR codes and add them to your marketing materials.</li>
    <li>Track your link performance and optimize your campaigns.</li>
</ol>
    </Row>
    
    <Row className='mt-5'>
    <h3>Ready to take your Shopify store to the next level? Try TrimyLink for free today!</h3>

    <a href='https://trimylink.io/signup'>Sign up now</a>
    `,
    image: shopifyLogo,
  },
  {
    order: 2,
    title:
      'The Link Shortener That Does More: Why TrimyLink is the Ultimate Solution for Your Online Presence',
    shortDescription: `
    In the digital age, effective link management is essential for individuals and businesses alike. Long, cumbersome URLs can be difficult to share, track, and remember, hindering your ability to connect with your audience.  That's where TrimyLink comes in -  a powerful link shortener that goes beyond basic functionality to empower your online presence.`,
    description: `
    <Row className='mt-5'>
    <h3><b>Why TrimyLink is More Than Just a Link Shortener:</b></h3>
    <ul>
    <li><b>Branded Short Links:</b> Establish a professional image with custom short URLs that reflect your brand identity.</li>
    <li><b>QR Codes:</b> Bridge the gap between offline and online marketing by effortlessly generating scannable QR codes.</li>
    <li><b>Robust Analytics:</b> Gain in-depth insights into link performance, including click-through rates, geographic data, and referral sources.</li>
    <li><b>Link in Bio:</b> Create a stunning, customizable landing page to showcase all your important links in one place.</li>
    <li><b>Seamless Integrations:</b> Connect TrimyLink with your favorite tools and platforms for automated workflows.</li>
    </ul>
    </Row>

    <Row className='mt-5'>
    <h3><b>TrimyLink Use Cases for Every Need:</b></h3>
    <ul>
    <li><b>Social Media Marketers:</b> Maximize the impact of your social media campaigns with trackable, branded links and a beautiful link in bio page.</li>
    <li><b>Content Creators:</b> Drive more traffic to your content and easily share links across multiple platforms.</li>
    <li><b>Ecommerce Businesses:</b> Optimize product links for better click-through rates and track conversions with ease.</li>
    <li><b>Event Organizers:</b> Create QR codes for event registration, promotions, and information sharing.</li>
    <li><b>Educators:</b> Share resources with students and track their engagement.</li>
    </ul>
    </Row>

    <Row className='mt-5'>
<h3><b>Why Choose TrimyLink?</b></h3>
<ul>
<li><b>User-Friendly Interface:</b> Our intuitive dashboard makes it easy to create, manage, and track your links.</li>
<li><b>Affordable Plans:</b> We offer flexible pricing options to suit your needs, including a free plan for basic usage.</li>
<li><b>Dedicated Support:</b> Our team is here to help you make the most of TrimyLink.</li>
</ul>
    </Row>

    <Row className='mt-5'>
    <h3><b>Get Started with TrimyLink Today:</b></h3>
    <p>Ready to simplify your links and elevate your brand? Sign up for a free TrimyLink account today and experience the difference for yourself!</p>

    <a href='https://trimylink.io>Sign up now</a>
    </Row>
    `,
    image: WhyTrimyLinkIsTheUltimateSolutionforYourOnlinePresence,
  },
  {
    order: 1,
    title: 'Unlock the Secrets of QR Code Marketing:  A Comprehensive Guide',
    shortDescription: `
    QR codes (Quick Response codes) are no longer a novelty – they're a powerful marketing tool that can connect your offline and online worlds.  If you're not leveraging QR codes in your marketing strategy, you're missing out on valuable opportunities to engage your audience and drive conversions.`,
    description: `
    <Row className='mt-5'>
    <h3><b>How QR Codes Can Revolutionize Your Marketing:</b></h3>
    <ul>
    <li><b>Bridge the Gap:</b> Connect your print materials (brochures, flyers, packaging) with your online presence.</li>
    <li><b>Drive Traffic:</b> Direct users to specific landing pages, product pages, or social media profiles.</li>
    <li><b>Engage Your Audience:</b> Create interactive experiences like contests, polls, or augmented reality.</li>
    <li><b>Gather Feedback:</b> Collect valuable customer feedback through surveys and polls.</li>
    <li><b>Offer Exclusive Deals:</b> Provide special discounts or promotions to users who scan your QR codes.</li>
    </ul>
    </Row>
  
    <Row className='mt-5'>
    <h3><b>Creating Effective QR Codes with TrimyLink:</b></h3>
    <ul>
    <li><b>Brand Your QR Codes:</b> Add your logo, colors, and other elements to match your brand identity.</li>
    <li><b>Track Scan Data:</b> Gain insights into how many people are scanning your codes and where they're coming from.</li>
    <li><b>Integrate with Short Links:</b> Combine the power of QR codes with short, trackable links for a seamless user experience.</li>
    </ul>
    </Row>
  
    <Row className='mt-5'>
    <h3><b>QR Code Best Practices:</b></h3>
    <ul>
    <li><b>Clear Call to Action:</b> Include a clear call to action (e.g., "Scan to Learn More," "Scan for a Special Offer") to encourage users to scan.</li>
    <li><b>High-Quality Printing:</b> Ensure the QR code is printed at a high resolution so it can be easily scanned.</li>
    <li><b>Optimal Placement:</b> Place QR codes in visible and accessible locations.</li>
    <li><b>Test Thoroughly:</b> Always test your QR codes before deploying them to ensure they work as expected.</li>
    </ul>
    </Row>
  
    <Row className='mt-5'>
    <h3><b>Take Your Marketing to the Next Level with QR Codes:</b></h3>
    <p>QR codes are a versatile and cost-effective way to enhance your marketing efforts and connect with your audience in new ways. With TrimyLink's user-friendly QR code generator, you can create custom codes that are both functional and visually appealing.</p>
  
    <a href='https://trimylink.io>Create Your QR Code Now</a>
    </Row>
    `,
    image: UnlockTheSecretsOfQRCodeMarketing,
  },
];
export function Blogs() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Row>
        {blogs &&
          blogs
            .sort((a, b) => a.order - b.order)
            .map((blog) => {
              return (
                <Col sm={3}>
                  <Card className='mt-3 mx-3'>
                    <Card.Title className='text-center py-3 px-3 '>
                      {blog?.title}
                    </Card.Title>
                    <Card.Body>
                      <Card.Text>
                        <img
                          className='w-100'
                          src={blog?.image}
                          alt='trimy link blog'
                        />
                        <p className='mt-3'>{blog.shortDescription}</p>
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className='d-flex flex-column align-items-end bg-transparent '>
                      <Button
                        variant='primary'
                        onClick={() => {
                          if (blog)
                            navigate('/blog', { state: { blog: blog } });
                        }}
                      >
                        Read more
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
      </Row>
      <Footer />
    </>
  );
}
