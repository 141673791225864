import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Modal, Badge } from 'react-bootstrap';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';
import { Dropdown } from 'react-bootstrap';
import { title } from 'process';
import { useNavigate } from 'react-router';
import { set } from 'date-fns';

export function ManageSubscription() {
  const [subscription, setSubscription] = useState<any>(null);
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isFreeTier, setIsFreeTier] = useState(false);
  const [subscriptionNotActive, setSubscriptionNotActive] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [changePlanNames, setChangePlanNames] = useState<any[]>([]);
  const [showUpgradeSuccess, setShowUpgradeSuccess] = useState(false);
  const plans = [
    { title: 'Free 0$/month', name: 'FREE PLAN' },
    { title: 'Basic 6$/month', name: 'BASIC PLAN' },
    { title: 'Standard 18$/month', name: 'STANDARD PLAN' },
    { title: 'Premium 30$/month', name: 'PREMIUM PLAN' },
  ];

  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const navigate = useNavigate();
  const handlePlanChange = (plan: any) => {
    setSelectedPlan(plan);
    setShowConfirm(true);
  };

  const handleConfirmChange = async () => {
    if (subscription.planName.toUpperCase() === 'FREE PLAN') {
      navigate('/checkout', {
        state: { tierName: selectedPlan.split(' ')[0] },
      });
      return;
    }
    setShowConfirm(false);
    setLoading(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/checkout/upgrade-session`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ tierName: selectedPlan }),
        }
      );
      setLoading(false);
      if (response.ok) {
        fetchSubscription();
        console.log(selectedPlan.toUpperCase());
        setShowUpgradeSuccess(true);
        if (selectedPlan.toUpperCase().includes('BASIC')) {
          localStorage.setItem('tier', 'Basic');
        } else if (selectedPlan.toUpperCase().includes('STANDAR')) {
          localStorage.setItem('tier', 'Standard');
        } else if (selectedPlan.toUpperCase().includes('PREMIUM')) {
          localStorage.setItem('tier', 'Premium');
        }
      } else {
        const result = await response.json();
        setErrorText(result?.message);
        setShowError(true);
      }
    } catch (error) {
      setLoading(false);
      setErrorText(
        `Error while upgrading plan --- Reference Number : ${requestId}`
      );
      setShowError(true);
    }
  };

  async function fetchSubscription() {
    setLoading(true);
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/subscription`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setLoading(false);
      setShow(false);
      if (!response.ok) {
        if (response.status === 404) {
          setErrorText('Subscription not found');
          setShowError(true);
        } else {
          const result = await response.json();

          if (result.code === 700059) {
            setSubscriptionNotActive(true);
          } else {
            setErrorText(result?.message);
            setShowError(true);
          }
        }
      } else {
        setErrorText('');
        setShowError(false);
        const result = await response.json();
        if (result.planName.toUpperCase() === 'FREE PLAN') {
          setIsFreeTier(true);
        }
        setSubscription(result);

        if (result.planName.toUpperCase() === 'FREE PLAN') {
          setChangePlanNames(
            plans.filter(
              (plan) =>
                plan.name.toUpperCase() !== result.planName.toUpperCase()
            )
          );
        } else {
          if (result.planName.toUpperCase() === 'BASIC PLAN') {
            setChangePlanNames(
              plans.filter(
                (plan) =>
                  plan.name.toUpperCase() !== result.planName.toUpperCase() &&
                  plan.name.toUpperCase() !== 'FREE PLAN'
              )
            );
          }
          if (result.planName.toUpperCase() === 'STANDARD PLAN') {
            setChangePlanNames(
              plans.filter(
                (plan) =>
                  plan.name.toUpperCase() !== result.planName.toUpperCase() &&
                  plan.name.toUpperCase() !== 'FREE PLAN' &&
                  plan.name.toUpperCase() !== 'BASIC PLAN'
              )
            );
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorText(
        `Error fetching subscription details --- Reference Number : ${requestId}`
      );
      setShowError(true);
    }
  }
  useEffect(() => {
    fetchSubscription();
  }, []);

  const handleCancel = async () => {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/subscription/cancel`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
        }
      );
      setShow(false);
      if (response.ok) {
        handleClose();
        fetchSubscription();
      } else {
        if (response.status === 400) {
          const error = (await response.json()).message;
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      }
    } catch (error) {
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Loading visible={loading} />
        </>
      ) : (
        <>
          <div className='my-subscriptions '>
            <h2>My Subscriptions</h2>
            <Row xs={1} md={2} lg={3} className='g-4 '>
              {subscription && (
                <Col key={subscription.planName} className='w-100'>
                  <Card border='light' className=''>
                    <Card.Body>
                      <Card.Title>
                        {subscription.planName}{' '}
                        {subscription.cancelled ? (
                          <>
                            <Badge bg='danger'>Cancelled</Badge>
                          </>
                        ) : (
                          ''
                        )}
                      </Card.Title>

                      <Row className='justify-content-between mt-3'>
                        <Col>
                          <p className='mb-0 '>
                            {isFreeTier ? (
                              'Next Billing (N/A)'
                            ) : subscription.cancelled ? (
                              <>
                                <b>Active Till {subscription.nextBilling} </b>
                              </>
                            ) : (
                              <p>Next Billing: {subscription.nextBilling}</p>
                            )}
                          </p>
                          <p className='text-muted mb-0'>
                            {subscription.price}
                          </p>
                        </Col>
                      </Row>
                      <Row className='mt-3 justify-content-end  '>
                        <Col md={6} xs='auto'>
                          {subscription.planName.toUpperCase() !==
                            'PREMIUM PLAN' &&
                            !subscription.cancelled && (
                              <Dropdown className='mx-2 mt-3'>
                                <Dropdown.Toggle
                                  variant='outline-secondary'
                                  id='dropdown-basic'
                                >
                                  Upgrade Plan
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {changePlanNames.map((plan) => (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handlePlanChange(plan.name)
                                      }
                                    >
                                      {plan.title}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                        </Col>
                        <Col md={6} xs='auto'>
                          <Button
                            variant='danger'
                            className='mx-2 mt-3 '
                            onClick={handleShow}
                            disabled={isFreeTier || subscription.cancelled}
                          >
                            Cancel Subscription
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>

            <h2 className='mt-5'>Previous Subscriptions</h2>
            <Row xs={1} md={2} lg={3} className='g-4 '>
              {subscription &&
                subscription.previousSubscription &&
                subscription.previousSubscription.map((prevSub: any) => (
                  <Col key={subscription.planName} className='w-100'>
                    <Card border='light' className=''>
                      <Card.Body>
                        <Card.Title>{prevSub.planName}</Card.Title>

                        <Row className='justify-content-between mt-3'>
                          <Col>
                            <p className='mb-0 '>
                              {prevSub.from} - {prevSub.to}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>

          {subscriptionNotActive && (
            <>
              <div className='alert alert-danger mt-5' role='alert'>
                Your subscription is not active. Please contact support for more
                information
              </div>
            </>
          )}
        </>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel your subscription?</p>
          <p className='text-danger'>
            You will continue to enjoy your current plan's features until the
            end of your billing cycle. After that, your subscription will
            automatically switch to the <b> Free tier.</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            No
          </Button>
          <Button variant='danger' onClick={handleCancel}>
            Yes, Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />

      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade Your Plan and Keep Your Benefits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>
              You're about to upgrade to the <b>{selectedPlan}</b>. Your default
              payment method will be charged immediately, and your new plan will
              take effect right away.
            </p>
          </Row>
          <Row>
            <p>
              The good news? You won't lose any of your existing features for
              the first month! Any remaining URL shorteners or QR codes from
              your current plan will be added to your new plan's limits for this
              initial period. After the first month, your limits will reset to
              those of your new plan.
            </p>
          </Row>
          <Row>
            <p>
              Click <b className='text-success'>"Continue"</b> to proceed with
              your upgrade and enjoy enhanced features!
            </p>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowConfirm(false)}>
            No
          </Button>
          <Button variant='success' onClick={handleConfirmChange}>
            Continue
          </Button>
        </Modal.Footer>

        <Modal
          show={showUpgradeSuccess}
          onHide={() => setShowUpgradeSuccess(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upgrade Successful</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your plan has been successfully upgraded!</Modal.Body>
          <Modal.Footer>
            <Button
              variant='success'
              onClick={() => setShowUpgradeSuccess(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    </>
  );
}
