import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select';

interface AddBioLinkModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  addLink: (event: any) => void;
  disabledButton: boolean;
  setLinkText: React.Dispatch<React.SetStateAction<string>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setIcon: React.Dispatch<React.SetStateAction<string>>;
  options: any[];
  formatOptionLabel: (data: any) => JSX.Element;
  setSelectedLinkIcon: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedLinkIconUrl: React.Dispatch<React.SetStateAction<string | null>>;
}
export function AddBioLinkModal({
  show,
  setShow,
  addLink,
  disabledButton,
  setLinkText,
  setTitle,
  setIcon,
  options,
  formatOptionLabel,
  setSelectedLinkIcon,
  setSelectedLinkIconUrl,
}: AddBioLinkModalProps) {
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addLink}>
            <Form.Group controlId='formLink'>
              <Form.Label>Link</Form.Label>
              <Form.Control
                required
                type='text'
                placeholder='Enter link'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLinkText(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group controlId='formTitle'>
              <Form.Label className='mt-2'>Title</Form.Label>
              <Form.Control
                type='text'
                required
                placeholder='Enter title'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Group>
                <Form.Label className='mt-2'>Upload Icon</Form.Label>
                <Form.Control
                  type='file'
                  id='formFile'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file = event.target.files?.[0] || null;
                    setSelectedLinkIcon(file);
                    console.log('selfile:', file);
                    setSelectedLinkIconUrl(
                      file ? URL.createObjectURL(file) : null
                    );
                  }}
                />
              </Form.Group>
            </Form.Group>
            <p className='mt-3'>Or Select from below icons </p>
            <Select
              options={options}
              formatOptionLabel={formatOptionLabel}
              onChange={(selectedOption: any) => {
                console.log('selected: ', selectedOption);
                setIcon(selectedOption?.value);
              }}
            />
            <Row>
              <Col xs={9} sm={9} md={9}>
                <Button
                  variant='success'
                  type='submit'
                  className='mt-3 w-100'
                  disabled={disabledButton}
                >
                  Save Changes
                </Button>
              </Col>
              <Col xs={3} sm={3} md={3}>
                <Button
                  variant='secondary'
                  onClick={() => setShow(false)}
                  className='mt-3 w-100'
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
