// useLogout.ts
import { useContext } from 'react';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';

export function useLogout() {
  const trimyLinkCtx = useContext(TrimyLinkContext);

  async function logout() {
    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/logout`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'request-id': requestId,
            authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
          },
          redirect: 'follow',
        }
      );

      await response.json();
      if (response.ok) {
        trimyLinkCtx.setLoginLogOutButtonText('Login');
        localStorage.setItem('loginLogOutButtonText', 'Login');
        trimyLinkCtx.setSignUpProfileButtonText('Sign Up Free');
        localStorage.setItem('signUpProfileButtonText', 'Sign Up Free');
        trimyLinkCtx.setGetStartedGenerateButtonText('Get Started For Free');
        localStorage.setItem(
          'getStartedGenerateButtonText',
          'Get Started For Free'
        );
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('tier');
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  return logout;
}
