import { Container, Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import { MdModeEditOutline, MdDelete } from 'react-icons/md';
import { LinkInBioProfileCard } from './LinkInBioProfileCard';
import { useEffect, useState } from 'react';
import { link } from 'fs';
import { AddBioLinkModal } from '../../products/link-in-bio/AddBioLinkModal';
import { BsFillThreadsFill } from 'react-icons/bs';
import { CiFacebook, CiInstagram, CiLinkedin } from 'react-icons/ci';
import {
  FaFacebook,
  FaInstagramSquare,
  FaTiktok,
  FaLinkedin,
  FaYoutube,
  FaTwitch,
} from 'react-icons/fa';
import { FaXTwitter, FaSquareXTwitter, FaThreads } from 'react-icons/fa6';
import { FiYoutube } from 'react-icons/fi';
import { IoMdGlobe } from 'react-icons/io';
import { RiTiktokLine } from 'react-icons/ri';
import { LinkInBioPreview } from './LinkInBioPreview';
import { ColorPickerComponent } from '../../products/link-in-bio/ColorPickerComponent';
import { set } from 'idb-keyval';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';

interface LinkInBio {
  createdBy: string;
  title: string;
  bioImage: string;
  bioLinks: [
    {
      title: string;
      url: string;
      icon?: string;
      shortid: string;
      customIcon?: any;
    }
  ];
  color1: string;
  color2: string;
  shortId: string;
  createdAt: string;
  bioUsername: string;
  textColor: string;
}

export function ProfileLinkInBioTab({
  linkInBio,
  deletedLink,
  setDeletedLink,
  savedLinkInBio,
  setSavedLinkInBio,
  isAnother,
}: {
  linkInBio: LinkInBio;
  deletedLink: any;
  setDeletedLink: any;
  savedLinkInBio: any;
  setSavedLinkInBio: any;
  isAnother: any;
}) {
  const [userName, setUserName] = useState('');
  const [title, setTitle] = useState('');
  const [linkText, setLinkText] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [icon, setIcon] = useState('');

  const [selectedLinkIcon, setSelectedLinkIcon] = useState<File | null>(null);
  const [selectedLinkIconUrl, setSelectedLinkIconUrl] = useState<string | null>(
    null
  );
  const [disabledButton, setDisabledButton] = useState(false);
  const [show, setShow] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [textColor, setTextColor] = useState('black');
  const [bioLinks, setBioLinks] = useState<any[]>();
  const [bioImage, setBioImage] = useState('');
  const [color1, setColor1] = useState('white');
  const [color2, setColor2] = useState('white');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(null);
  const [saved, setSaved] = useState(false);
  const [addedLinks, setAddedLinks] = useState<any[]>([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const options = [
    { value: 'CiFacebook', label: 'Facebook', icon: CiFacebook },
    { value: 'FaFacebook', label: 'Facebook', icon: FaFacebook },

    { value: 'CiInstagram', label: 'Instagram', icon: CiInstagram },
    { value: 'FaInstagramSquare', label: 'Instagram', icon: FaInstagramSquare },

    { value: 'FaXTwitter', label: 'X', icon: FaXTwitter },
    { value: 'FaSquareXTwitter', label: 'X', icon: FaSquareXTwitter },

    { value: 'FaTiktok', label: 'Tiktok', icon: FaTiktok },
    { value: 'RiTiktokLine', label: 'Tiktok', icon: RiTiktokLine },

    { value: 'CiLinkedin', label: 'LinkedIn', icon: CiLinkedin },
    { value: 'FaLinkedin', label: 'LinkedIn', icon: FaLinkedin },

    { value: 'BsFillThreadsFill', label: 'Threads', icon: BsFillThreadsFill },
    { value: 'FaThreads', label: 'Threads', icon: FaThreads },

    { value: 'FiYoutube', label: 'Youtube', icon: FiYoutube },
    { value: 'FaYoutube', label: 'Youtube', icon: FaYoutube },
    { value: 'FaTwitch', label: 'Twitch', icon: FaTwitch },

    { value: 'IoMdGlobe', label: 'Website', icon: IoMdGlobe },
  ];
  useEffect(() => {
    setUserName(linkInBio.title);
    setTextColor(linkInBio.textColor);

    setBioLinks(linkInBio.bioLinks);
    setBioImage(linkInBio.bioImage);
    setTitle(linkInBio.title);
    setColor1(linkInBio.color1 || '#fafafa');
    setColor2(linkInBio.color2 || '#fafafa');
    setSelectedFileUrl(linkInBio.bioImage);
  }, [linkInBio]);

  async function saveLinkInBio() {
    setLoadingVisible(true);
    const formData = new FormData();
    formData.append('title', title);
    if (selectedFile) formData.append('bioImage', selectedFile);
    formData.append('color1', color1);
    formData.append('color2', color2);
    formData.append('textColor', textColor);
    formData.append('username', linkInBio.bioUsername);
    formData.append('isAnother', 'false');
    let links: {
      title: any;
      url: any;
      icon: any;
      customIcon: any;
    }[] = [];

    const linkPromises = addedLinks?.map(async (link, index) => {
      let bioLink = {
        title: link.title,
        url: link.url,
        icon: link.icon,
        customIcon: undefined,
      };

      if (link.customIcon) {
        console.log('addcustom', selectedFile);
        bioLink.customIcon = link.customIcon.name;
        formData.append(`linkIcons[${index}]`, link.customIcon);
      }
      links.push(bioLink);
    });
    console.log('linksSize:', links.length);
    formData.append('bioLinks', JSON.stringify(links));
    if (linkPromises) await Promise.all(linkPromises);

    const requestId = Date.now().toString();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/link-in-bio/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
            'request-id': requestId,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        if (response.status === 400) {
          const error = (await response.json()).message;
          console.log('tab', error);
          setErrorText(error);
          setShowError(true);
        } else {
          setErrorText(
            `Ops something went wrong at our end -- Reference Number: ${requestId}`
          );
          setShowError(true);
        }
      } else {
        const result = await response.json();
        setSavedLinkInBio(!savedLinkInBio);
        setAddedLinks([]);
      }
    } catch (error) {
      console.log('error:', error);
      setBioLinks([]);
    }
    setLoadingVisible(false);
  }

  const formatOptionLabel = ({
    label,
    icon: Icon,
  }: {
    label: any;
    icon: any;
  }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon />
      <span style={{ marginLeft: '10px' }}>{label}</span>
    </div>
  );

  async function addLink(event: any) {
    event.preventDefault();

    if (icon) {
      const linkToAdd = {
        title: linkTitle,
        url: linkText,
        icon: icon,

        shortid: '',
      };
      setBioLinks([...(bioLinks || []), linkToAdd]);
      setAddedLinks([...addedLinks, linkToAdd]);
    } else {
      const linkToAdd = {
        title: linkTitle,
        url: linkText,

        customIcon: selectedLinkIcon || '',
        shortid: '',
      };
      setBioLinks([...(bioLinks || []), linkToAdd]);
      setAddedLinks([...addedLinks, linkToAdd]);
    }
    setLinkText('');
    setIcon('');
    setLinkTitle('');
    setSelectedLinkIcon(null);
    setSelectedLinkIconUrl(null);

    setShow(false);
  }
  return (
    <Container className='y-flex align-items-center justify-content-center center'>
      <Loading visible={loadingVisible} />
      <Row className='d-flex align-items-end justify-content-end mb-3'>
        <Button variant='success' className='w-25' onClick={saveLinkInBio}>
          Save Changes
        </Button>
      </Row>

      <Row>
        <Col sm={8} md={8}>
          <div>
            <Row>
              <h4>Bio Image</h4>
            </Row>
            <Row>
              <Col sm={6} md={6}>
                <div className='y-flex justify-content-center align-items-center center'>
                  {selectedFileUrl ? (
                    <img
                      src={selectedFileUrl || ''}
                      alt='bioImage'
                      style={{ width: '200px', height: '200px' }}
                    />
                  ) : (
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%',
                        border: '1px solid black',
                        backgroundImage:
                          'repeating-linear-gradient(45deg, white, gray 10px, white 10px, white 20px)',
                      }}
                    />
                  )}
                </div>
              </Col>

              <Col className=' d-flex justify-content-center align-items-center center'>
                <Form.Group controlId='formFile' className=''>
                  <Form.Control
                    type='file'
                    accept='image/*'
                    hidden
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const file = event.target.files?.[0] || null;
                      setSelectedFile(file);
                      setSelectedFileUrl(
                        file ? URL.createObjectURL(file) : null
                      );
                      setBioImage(file ? URL.createObjectURL(file) : '');
                    }}
                  />
                  <Button variant='success' as='label' htmlFor='formFile'>
                    <MdModeEditOutline size={30} />
                    Upload Photo
                  </Button>
                </Form.Group>
                <Button variant='danger' className='mx-2' onClick={() => {}}>
                  <MdDelete size={30} className='' />
                  Delete Photo
                </Button>
              </Col>
            </Row>

            <Row className='mt-5'>
              <h4 className=''>Username</h4>

              <InputGroup className='mb-3 '>
                <InputGroup.Text id='basic-addon1'>@</InputGroup.Text>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Username'
                  aria-label='Username'
                  value={linkInBio?.bioUsername}
                  disabled
                />
              </InputGroup>
            </Row>

            <Row className='mt-5'>
              <h4 className=''>Title</h4>

              <InputGroup className='mb-3 w-50'>
                <InputGroup.Text id='basic-addon1'>~</InputGroup.Text>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Username'
                  aria-label='Username'
                  value={title}
                  onChange={(e: any) => {
                    setTitle(e.target.value);
                  }}
                />
              </InputGroup>
            </Row>

            <Row>
              <h4 className=''>Text Color</h4>

              <InputGroup className='mb-3 w-50'>
                <input
                  type='color'
                  className='form-control'
                  value={textColor}
                  onChange={(e: any) => {
                    setTextColor(e.target.value);
                  }}
                />
              </InputGroup>
            </Row>

            <Row>
              <ColorPickerComponent
                color1={color1}
                setColor1={setColor1}
                color2={color2}
                setColor2={setColor2}
                textColor={textColor}
                setTextColor={setTextColor}
              />
            </Row>

            <Row className='mt-5'>
              <Row>
                <Col sm={6} md={6}>
                  <h4>Bio Links</h4>
                </Col>
                <Col sm={6} md={6}>
                  <Button
                    variant='success'
                    className='w-50'
                    onClick={() => setShow(true)}
                  >
                    Add Link
                  </Button>
                </Col>
              </Row>

              {bioLinks &&
                bioLinks.map((link: any, index: any) => (
                  <LinkInBioProfileCard
                    link={link}
                    bioUsername={linkInBio.bioUsername}
                    deletedLink={deletedLink}
                    setDeletedLink={setDeletedLink}
                  />
                ))}
            </Row>
          </div>
        </Col>

        <Col sm={4} md={4} className='px-5'>
          <LinkInBioPreview
            bioImage={bioImage}
            title={title}
            textColor={textColor}
            bioLinks={bioLinks}
            color1={color1}
            color2={color2}
          />
        </Col>
      </Row>

      <AddBioLinkModal
        show={show}
        setShow={setShow}
        setTitle={setLinkTitle}
        setLinkText={setLinkText}
        setIcon={setIcon}
        options={options}
        formatOptionLabel={formatOptionLabel}
        addLink={addLink}
        disabledButton={disabledButton}
        setSelectedLinkIcon={setSelectedLinkIcon}
        setSelectedLinkIconUrl={setSelectedLinkIconUrl}
      />

      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </Container>
  );
}
