import React, { useCallback, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from 'react-router-dom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const apikey = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;
const stripePromise = loadStripe(apikey);

export function CheckoutForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    if (
      !localStorage.getItem('jwtToken') ||
      !state ||
      !state.tierName ||
      state.tierName.length === 0
    ) {
      navigate('/');
    } else {
      console.log('checkout tier', state.tierName);
      fetchClientSecret();
    }
  }, []);

  function fetchClientSecret() {
    const requestId = Date.now().toString();

    return fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/checkout/create-checkout-session`,
      {
        method: 'POST',
        headers: {
          'request-id': requestId,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
        body: JSON.stringify({ tierName: state.tierName, email: state.email }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: any) => {
        window.location.href = data.session_url;
      })
      .catch((error) => console.log(error));
  }

  // const options = { fetchClientSecret };

  return <></>;
}
