import { Container, Row } from 'react-bootstrap';
import Header from './home-page/Header';
import { Footer } from './home-page/Footer';

export function PrivacyPolicy() {
  return (
    <Container className='mx-3 '>
      <Row>
        <Header />
      </Row>

      <Row>
        <h3>
          <b>PRIVACY POLICY</b>
        </h3>
      </Row>

      <Row className='mt-5'>
        <h4>
          <b>Welcome to TrimyLink!</b>
        </h4>
        <p>
          TrimyLink values your privacy and the protection of your personal
          data. This privacy policy describes what information we collect from
          you, how we collect it, how we use it, how we obtain your consent, how
          long we keep it in our databases and, if necessary, with whom we share
          it. By using the platform and services, you are accepting the
          practices described in this privacy policy. Your use of the platform
          is also subject to our terms and conditions. In this privacy policy,
          the words "platform" refer to the TrimyLink website and the set of
          services offered by TrimyLink, "we", "us", "our", and "TrimyLink"
          refers to TrimyLink, and "you" and "user", refers to you, the
          TrimyLink user. This privacy policy may change from time to time. Your
          continued use of the platform and services after we make changes to
          this privacy policy will be deemed acceptance of those changes, so
          please check this policy periodically for updates. This privacy policy
          has been developed and is maintained in accordance with all applicable
          national and international privacy and personal data protection laws
          and regulations and specifically, the Personal Data Protection Law
          (United Arab Emirates Law) and the General Data Protection Regulation
          (GDPR - European regulation).
        </p>
      </Row>

      <Row>
        <ol>
          <li>
            <b> GENERAL INFORMATION</b>
            <br></br>The personal data of users that are collected and processed
            through:<br></br>
            <ul>
              <li>
                <p>
                  https://trimylink.io <br></br>
                  Will be under the responsibility and in charge of:
                </p>
              </li>
              <li>
                <b>TrimyLink.</b>
              </li>
              <li>
                <b>Email: support@trimylink.io</b>
              </li>
            </ul>
          </li>

          <li className='mt-3'>
            <b> HOW WE OBTAIN YOUR CONSENT</b>
            <p>
              When you use the platform, register as a user, purchase a
              subscription plan, use the functionalities available on the
              platform, contact us through our contact information and contact
              form and provide us with personal information to communicate with
              you, you consent to our collection, storage and use of your
              information under the terms set out in this privacy policy.
            </p>
          </li>

          <li className='mt-3'>
            <b>TYPES OF INFORMATION COLLECTED</b>
            <p>
              The information we collect from our users helps us to continually
              improve the user experience on the platform. These are the types
              of information we collect:
            </p>
            <p>
              <b>Information you provide to us. </b>You provide information when
              you use the platform, register as a user, purchase a subscription
              plan, use the functionalities available on the platform, contact
              us through our contact information and contact form and provide us
              with personal information to communicate with you. As a result of
              those actions, you may provide us with the following information:
            </p>
            <ul>
              <li>Full name</li>
              <li>Username</li>
              <li>Date of birth</li>
              <li>Country</li>
              <li>Email</li>
              <li>Mobile Number</li>
            </ul>
            <p className='mt-2'>
              <b>Usage Data:</b> As you browse our website, certain usage data
              is automatically collected. These include technical information
              such as the IP address of your device, the type and version of
              your browser, the specific pages you visit on our website, the
              date and time of your access, the amount of time you spend on
              those pages, and other diagnostically relevant data.
            </p>
            <p>
              In the case of access via mobile devices, we also collect
              information specific to these devices. This may include, but is
              not limited to, the type of mobile device used, its unique
              identifier, the IP address of the device, the mobile operating
              system and the type of mobile browser, along with other
              identifiers and diagnostic data. In addition, we collect
              information that your browser automatically provides each time you
              visit our website or access our website using a mobile device.
            </p>

            <p>
              <b>Signing in with Google:</b> When registering or logging in to
              our platform via Google, users allow us to access their basic
              profile information, such as name and email. This process is
              governed by Google's privacy policies. We do not collect or store
              passwords for these accounts. By using this login method, users
              consent to the sharing of this information between our platform
              and external services. This consent can be revoked at any time by
              adjusting the user's privacy preferences.
            </p>
            <p>
              <b>Payment information:</b> Your credit card or payment card
              details will be processed by the payment processors available on
              the platform, who will process and store your data securely and
              for the sole purpose of processing subscription payments.
              TrimyLink reserves the right to engage any available payment
              processor.
            </p>
            <p>
              <b>Social Media:</b> On our website, we provide you with various
              links that allow you to interact with social media. We invite you
              to use these functions to share your information as you wish.
              However, we strongly encourage you to review the privacy and data
              protection policies of each of the social media sites you use
              through our website to understand how they handle your personal
              information
            </p>
            <ul>
              <li>
                Facebook:{' '}
                <a
                  style={{ color: 'blue', textDecoration: 'underline' }}
                  href='https://www.facebook.com/privacy/explanation'
                >
                  https://www.facebook.com/privacy/explanation
                </a>
              </li>
              <li>
                Instagram:{' '}
                <a
                  style={{ color: 'blue', textDecoration: 'underline' }}
                  href='http://instagram.com/about/legal/privacy'
                >
                  http://instagram.com/about/legal/privacy
                </a>
                /
              </li>
              <li>
                Twitter:{' '}
                <a
                  style={{ color: 'blue', textDecoration: 'underline' }}
                  href='https://twitter.com/privacy'
                >
                  https://twitter.com/privacy
                </a>
              </li>
              <li>
                Linkedin:{' '}
                <a
                  style={{ color: 'blue', textDecoration: 'underline' }}
                  href='https://www.linkedin.com/legal/privacy-policy'
                >
                  https://www.linkedin.com/legal/privacy-policy
                </a>
              </li>
            </ul>
            <p className='mt-2'>
              <b>Contact information:</b> We may access some personal
              information about the user, such as name and email address, when
              the user or any third party communicates with us through our
              contact information. Personal information provided through our
              contact information is not stored on any TrimyLink server and will
              be stored on the respective server of our email service.
            </p>
          </li>
          <li className='mt-3'>
            <b>HOW LONG WE KEEP YOUR DATA</b>
            <p>
              Personal data provided by users through the platform will be
              retained for the time necessary to provide the functionalities
              available on the platform, fulfill the legitimate purposes
              described in section 5 of this policy or until the user closes the
              user account or requests the deletion of their data. TrimyLink may
              retain personal data for a longer period provided that the user
              has consented to such processing, as long as such consent is not
              withdrawn. In addition, TrimyLink may be obliged to retain
              personal data for a longer period provided that this is required
              for compliance with a legal obligation or by order of an
              authority. Once the retention period has expired, the personal
              data will be deleted. Therefore, the right of access, the right of
              deletion, the right of rectification and the right to data
              portability cannot be asserted after the retention period has
              expired.
            </p>
          </li>
          <li className='mt-3'>
            <b>HOW WE USE YOUR INFORMATION (LEGITIMATE PURPOSES)</b>
            <p>
              In general, we use the information we collect primarily to
              provide, maintain, protect and improve our platform and to provide
              the functionalities available on the platform appropriately. We
              use personal information collected through our platform as
              described below:
            </p>
            <ul>
              <li>Facilitate registration for our users.</li>
              <li>Provide our subscription plans.</li>
              <li>Process subscription payments.</li>
              <li>Provide the functionalities available on the platform.</li>
              <li>
                Understand and improve your experience using our platform.
              </li>
              <li>
                Respond to your comments or questions through our support team.
              </li>
              <li>
                Send you related information, including confirmations, invoices,
                technical notices, updates, security alerts, and support and
                administrative messages.
              </li>
              <li>
                Send you notifications and relevant information about TrimyLink.
              </li>
              <li>TrimyLink marketing purposes.</li>
              <li>
                Process user requests related to the exercise of their rights
                over their personal data.
              </li>
              <li>
                Link or combine your information with other data we obtain from
                third parties to help us understand your needs and provide you
                with better service.
              </li>
              <li>
                Protect, investigate and deter fraudulent, unauthorized or
                illegal activities.
              </li>
            </ul>
          </li>

          <li className='mt-2'>
            <b>HOW WE SHARE INFORMATION</b>
            <p>
              Information about our users is an important part of our business,
              and we are not in the business of selling it to third parties. We
              share customer information only as described below.
            </p>
            <p>
              <b>Third-party suppliers.</b> We use third party services to
              perform certain functions on our platform. Some of these functions
              and services include: website creation and hosting, payment
              processing, registration and login, sending emails, and tracking
              and analyzing data on the platform.
            </p>
            <p>
              These third-party services and tools may have access to personal
              information needed to perform their functions, but may not use
              that information for other purposes. Information shared with these
              third-party services will be treated and stored in accordance with
              their respective privacy policies and our privacy policy.{' '}
            </p>
            <p>
              <b>E-mail communications.</b> By providing us with your email
              address, you consent and agree that we may send relevant content,
              notifications and information to your email address. Therefore,
              your email address may be shared with third party bulk email
              services for the sole and exclusive purpose of sending you
              relevant communications. If you wish to stop receiving
              communications from TrimyLink, you may unsubscribe at any time by
              using the "unsubscribe" option available in the same emails or by
              sending your request through our contact information.{' '}
            </p>
            <p>
              <b> Business Transfers.</b> In the event TrimyLink creates, merges
              with, or is acquired by another entity, your information will
              likely be transferred. TrimyLink will send you an email or post a
              prominent notice on our platform before your information becomes
              subject to another privacy policy.
            </p>
            <p>
              <b>Protection of TrimyLink and Others.</b> We release personal
              information when we believe release is appropriate to comply with
              the law, enforce or apply our terms and conditions and other
              agreements, or protect the rights, property, or safety of
              TrimyLink, our users, or others. This includes exchanging
              information with other companies and organizations for fraud
              protection and credit risk reduction.
            </p>
            <p>
              <b> Anonymous Information.</b> TrimyLink uses anonymous browsing
              information collected automatically by our servers primarily to
              help us administer and improve the platform. We may also use
              aggregated anonymous information to provide information about the
              platform to potential business partners and other unaffiliated
              entities. This information is not personally identifiable.
            </p>
          </li>

          <li>
            <b>DATA BREACH NOTIFICATIONS</b>
            <p>
              In the event of a security breach that compromises the
              confidentiality of the personal data of our users, TrimyLink
              undertakes to notify those affected in a timely manner. This
              notification will be made through the means of contact that have
              been provided by the user on our platform. We will take all
              reasonable measures to protect the information and remedy any
              situation that compromises the security of your data.
            </p>
          </li>
          <li>
            <b>INTERNATIONAL DATA TRANSFER</b>
            <p>
              By using our platform, you agree that your personal data may be
              transferred and processed outside your country of origin, where
              data protection laws may differ. TrimyLink is committed to taking
              the necessary steps to ensure that your data is treated in
              accordance with applicable privacy standards and is adequately
              protected during any international transfer.
            </p>
          </li>
          <li>
            <b> PROTECTION OF YOUR INFORMATION</b>
            <p>
              We grant access to your personal information only to those outside
              persons or services that have a legitimate need to know it and in
              accordance with our privacy policy. We adhere to
              industry-recognized security standards to protect your personal
              information, both during transmission and in storage. However, it
              is important to note that no method of transmission over the
              Internet or electronic storage is foolproof and 100% secure.
              Therefore, while we at TrimyLink strive to implement commercially
              viable data protection methods, we cannot ensure absolute security
              of your personal information. We undertake not to sell, distribute
              or transfer your personal data to unauthorized third parties,
              unless we have your explicit consent or are required by law to do
              so.
            </p>
          </li>
          <li>
            <b>RIGHTS </b>
            <p>
              Users who provide information through our platform, as data
              subjects, have the right to access, rectify, download or delete
              their information, as well as to restrict and oppose certain
              processing of their information. While some of these rights apply
              generally, others only apply in certain limited circumstances.
              These rights are described below:
            </p>
            <ul>
              <li>
                <b>Access and portability:</b> to access and know what
                information is stored on our servers, you can send us your
                request through our contact information.
              </li>
              <li>
                <b>Rectification, restriction, limitation and deletion:</b> You
                may also rectify, restrict, limit or delete much of your
                information.
              </li>
              <li>
                <b>Right to be informed:</b> Users of our platform will be
                informed, upon request, about what data we collect, how it is
                used, how long it is kept and whether it is shared with third
                parties.
              </li>
              <li>
                <b>Object:</b> Where we process your data based on our
                legitimate interests, as explained above, or in the public
                interest, you may object to this processing in certain
                circumstances. In such cases, we will stop processing your
                information unless we have legitimate grounds to continue
                processing it or where necessary for legal reasons.
              </li>
              <li>
                <b>Withdraw consent:</b> Where you have previously given your
                consent, for example to allow us to process and store your
                personal information, you have the right to withdraw your
                consent to the processing and storage of your information at any
                time. For example, you can withdraw your consent by updating
                your settings. In certain cases, we may continue to process your
                information after you have withdrawn your consent if we have a
                lawful basis for doing so or if your withdrawal of consent was
                limited to certain processing activities.
              </li>
              <li>
                <b>Complaint:</b> If you wish to lodge a complaint about our use
                of your information (and without prejudice to any other rights
                you may have), you have the right to do so with your local
                supervisory authority. Users may exercise all of these rights by
                contacting us via the contact information or contact page.
              </li>
              <li>
                <b>
                  Rights related to automated decision-making, including
                  profiling:
                </b>{' '}
                Users may request that we provide them with a copy of the
                automated processing activities we conduct if they believe that
                data is being unlawfully processed.
              </li>
            </ul>
            <p>
              Users or holders of personal data provided through the platform
              may exercise these rights over their personal data at any time and
              without limitation by sending their request through our contact
              information. The request to exercise their rights will be attended
              and answered within a maximum period of 10 working days.{' '}
            </p>
          </li>
          <li>
            <b> RIGHT OF EDITING AND DELETION</b>
            <p>
              We are committed to ensuring that our users maintain full control
              over their data. We therefore grant users the explicit right to
              modify or delete any information they have provided to the
              platform.
            </p>
            <p>
              Users may exercise this right directly through their user account
              by facilitating the editing or deletion of data such as their
              personal information, profile information, etc. In the event that
              certain requests cannot be handled through the user account, users
              may contact us through our contact information to make the
              necessary edits or deletions.
            </p>
            <p>
              Our approach is designed to ensure that users have constant and
              effective control over their data, allowing them to manage their
              information in a way that protects their privacy and autonomy.
            </p>
          </li>
          <li>
            <b> PROTECTION OF CHILDREN'S ONLINE PRIVACY </b>
            <p>
              We comply with national and international data protection
              regulations regarding the protection of children's personal data.
              We do not collect any information from children under the age of
              13. If we become aware that a child under the age of 13 has
              provided us with personal information, we will take immediate
              steps to delete such information.
            </p>
          </li>
          <li>
            <b> THIRD PARTIES</b>
            <p>
              Except as expressly included in this privacy policy, this document
              addresses only the use and disclosure of information TrimyLink
              collects from you. If you disclose your information to others,
              whether other users or TrimyLink vendors, different rules may
              apply to their use or disclosure of the information you disclose
              to them. TrimyLink does not control the privacy policies of third
              parties, and you are subject to the privacy policies of those
              third parties where applicable. TrimyLink is not responsible for
              the privacy or security practices of another Internet platform,
              including those linked to or from the TrimyLink platform. Please
              review the privacy policies of third-party websites or services
              that you access through the TrimyLink platform.
            </p>
          </li>
          <li>
            <b> CHANGES TO PRIVACY POLICY</b>
            <p>
              We reserve the right to change our privacy policy at any time.
              Changes will be promptly notified to our users and posted on the
              website. Your continued use of our website and services following
              these changes constitutes acceptance of the changes.
            </p>
          </li>
          <li>
            <b>CONTACT INFORMATION</b>
            <p>
              If you have questions or concerns about this privacy policy and
              the treatment and security of your data, please contact us through
              our contact forms or by using the following contact information:
            </p>
            <b>TrimyLink.</b>
            <b>Email: support@trimylink.io</b>
          </li>
        </ol>
      </Row>

      <Footer />
    </Container>
  );
}
