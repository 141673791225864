import { Card, Col, Container, Row } from 'react-bootstrap';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { Image } from 'react-bootstrap';
import logo from './../logo.svg';
import { BsFillThreadsFill } from 'react-icons/bs';
import { CiFacebook, CiLinkedin, CiInstagram } from 'react-icons/ci';
import {
  FaTiktok,
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaYoutube,
  FaTwitch,
} from 'react-icons/fa';
import { FaXTwitter, FaSquareXTwitter, FaThreads } from 'react-icons/fa6';
import { FiYoutube } from 'react-icons/fi';
import { IoMdGlobe } from 'react-icons/io';
import { RiTiktokLine } from 'react-icons/ri';
import { useEffect } from 'react';

const Icons = [
  { name: 'CiFacebook', icon: CiFacebook },
  { name: 'CiLinkedin', icon: CiLinkedin },
  { name: 'CiInstagram', icon: CiInstagram },
  { name: 'FaTiktok', icon: FaTiktok },
  { name: 'FaFacebook', icon: FaFacebook },
  { name: 'FaInstagramSquare', icon: FaInstagramSquare },
  { name: 'FaLinkedin', icon: FaLinkedin },
  { name: 'FaXTwitter', icon: FaXTwitter },
  { name: 'FaSquareXTwitter', icon: FaSquareXTwitter },
  { name: 'FaThreads', icon: FaThreads },
  { name: 'BsFillThreadsFill', icon: BsFillThreadsFill },
  { name: 'RiTiktokLine', icon: RiTiktokLine },
  { name: 'IoMdGlobe', icon: IoMdGlobe },
  { name: 'FiYoutube', icon: FiYoutube },
  { name: 'FaYoutube', icon: FaYoutube },
  { name: 'FaTwitch', icon: FaTwitch },
];

export function LinkInBioPreview({
  bioImage,
  title,
  textColor,
  bioLinks,
  color1,
  color2,
}: {
  bioImage: any;
  title: any;
  textColor: any;
  bioLinks: any;
  color1: any;
  color2: any;
}) {
  useEffect(() => {
    console.log('bioLinksPRev:', bioLinks);
  }, [bioLinks]);
  return (
    <>
      <DeviceFrameset device='iPhone X' color='gold' landscape={false}>
        <Container
          className='scrollable h-100 '
          style={{
            overflowY: 'auto',
            maxHeight: '100vh',
            background: color2
              ? `linear-gradient(to right, ${color1}, ${color2})`
              : color1,
            backgroundRepeat: 'no-repeat',
          }}
        >
          {bioImage && (
            <Row className='d-flex align-items-center justify-content-center w-100 '>
              <Image
                id='bioImage'
                src={bioImage}
                style={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: 'transparent',
                }}
                roundedCircle
                className='mt-5'
              />
            </Row>
          )}
          <Row>
            <div className='d-flex align-items-center justify-content-center w-100  mt-5'>
              <Row style={{ color: textColor }}>
                <h4>{title}</h4>
              </Row>
            </div>
          </Row>

          {bioLinks && Array.isArray(bioLinks) ? (
            <div>
              {bioLinks.map((link: any) => {
                let icon: any = null;
                let customIcon: any = null;

                console.log(link.icon);
                console.log(Icons);
                if (link.icon && link.icon !== '') {
                  icon = Icons.find((icon) => icon.name === link.icon)?.icon;
                }
                console.log(icon);
                if (link.customIcon) {
                  if (
                    typeof link.customIcon === 'string' &&
                    link.customIcon.startsWith('data:image')
                  )
                    customIcon = link.customIcon;
                  else customIcon = URL.createObjectURL(link.customIcon);
                }

                return (
                  <Card
                    className='d-flex flex-row align-items-center  justify-content-center center  mt-2 card-hover'
                    onClick={() =>
                      window.open(
                        link.url.startsWith('http')
                          ? link.url
                          : `http://${link.url}`,
                        '_blank'
                      )
                    }
                  >
                    <Col xs={2}>
                      {customIcon ? (
                        <Card.Img
                          variant='top'
                          className='mx-3'
                          src={customIcon}
                          style={{ width: '32px', height: '32px' }}
                        />
                      ) : (
                        icon && (
                          <Card.Img
                            variant='top'
                            as={icon}
                            className='mx-3'
                            style={{ width: '32px', height: '32px' }}
                          />
                        )
                      )}
                    </Col>
                    <Card.Body
                      className='d-flex flex-row align-items-center w-100'
                      style={{
                        width: '100vw',
                      }}
                    >
                      <Col>
                        <Card.Text
                          className='mr-3 project-font w-100'
                          style={{
                            color: textColor,
                            fontSize: '1.15rem',
                            width: '100%',
                          }}
                        >
                          <p
                            className='w-100'
                            style={{
                              width: '100vw',
                            }}
                          >
                            {link.title}
                          </p>
                        </Card.Text>
                      </Col>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          ) : (
            <h3>No links found.</h3>
          )}
        </Container>
      </DeviceFrameset>
    </>
  );
}
