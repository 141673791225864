import {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

interface TrimyLinkContextType {
  getStartedGenerateButtonText: string;
  setGetStartedGenerateButtonText: Dispatch<SetStateAction<string>>;
  loginLogOutButtonText: string;
  setLoginLogOutButtonText: Dispatch<SetStateAction<string>>;
  signUpProfileButtonText: string;
  setSignUpProfileButtonText: Dispatch<SetStateAction<string>>;
  profileTab: string;
  setSelectedProfileTab: Dispatch<SetStateAction<string>>;
}

export const TrimyLinkContext = createContext<TrimyLinkContextType>({
  getStartedGenerateButtonText: '',
  setGetStartedGenerateButtonText: () => {},
  loginLogOutButtonText: '',
  setLoginLogOutButtonText: () => {},
  signUpProfileButtonText: '',
  setSignUpProfileButtonText: () => {},
  profileTab: '',
  setSelectedProfileTab: () => {},
});

interface TrimyLinkProviderProps {
  children: ReactNode;
}

export const TrimyLinkProvider = ({ children }: TrimyLinkProviderProps) => {
  const [getStartedGenerateButtonText, setGetStartedGenerateButtonText] =
    useState('Get Started For Free');
  const [loginLogOutButtonText, setLoginLogOutButtonText] = useState('Login');
  const [signUpProfileButtonText, setSignUpProfileButtonText] =
    useState('Sign Up Free');

  const [profileTab, setSelectedProfileTab] = useState('info');

  return (
    <TrimyLinkContext.Provider
      value={{
        getStartedGenerateButtonText,
        setGetStartedGenerateButtonText,
        loginLogOutButtonText,
        setLoginLogOutButtonText,
        signUpProfileButtonText,
        setSignUpProfileButtonText,
        profileTab,
        setSelectedProfileTab,
      }}
    >
      {children}
    </TrimyLinkContext.Provider>
  );
};
