import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { URLShortenPage } from './products/shorten-url/URLShortenPage';
import { MainPage } from './home-page/MainPage';
import { QRShortenPage } from './products/shorten-qr/QRShortenPage';
import { LinkInBioIntroPage } from './products/link-in-bio/LinkInBioIntroPage';
import { Signup } from './auth/Signup';
import { Profile } from './profile/Profile';
import { Pricing } from './Pricing';
import { NotFound } from './NotFound';
import { RedirectToUrl } from './RedirectToUrl';
import { AboutUs } from './AboutUs';
import { TermsAndConditions } from './TermsAndConditions';
import { ContactUs } from './ContactUs';
import { LinkInBio } from './products/link-in-bio/LinkInBio';
import { Analytics } from './profile/analytics/Analytics';
import { ManageSubscription } from './profile/subscription/ManageSubscription';
import { CheckoutForm } from './checkout/Checkout';
import { Return } from './checkout/Retrurn';
import { PrivacyPolicy } from './PrivacyPolicy';
import { CookiePolicy } from './CookiePolicy';
import VerificationSuccessPage from './VerificationSuccessPage';
import { Blogs } from './Blogs';
import { BlogDetails } from './BlogDetails';
import { ForgotPassword } from './auth/ForgotPassword';
import { ApiKeysManagement } from './profile/ApiKeysManagement';
import { ApiDocumentation } from './ApiDocumentation';
import { BulkShortening } from './profile/BulkShortening';
import { ApiDocs } from './ApiDocs';
export function Root() {
  return (
    <Router>
      <>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/URLShortenPage' element={<URLShortenPage />} />
          <Route path='/QRShortenPage' element={<QRShortenPage />} />
          <Route
            path='/LinkInBioShortenPage'
            element={<LinkInBioIntroPage />}
          />
          <Route path='/Signup' element={<Signup />} />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/NotFound' element={<NotFound />} />
          <Route path='/link-in-bio/:username' element={<LinkInBio />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route
            path='/terms-and-conditions'
            element={<TermsAndConditions />}
          />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/analytics' element={<Analytics />} />

          <Route path='/checkout' element={<CheckoutForm />} />
          <Route path='/return' element={<Return />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/cookie-policy' element={<CookiePolicy />} />
          <Route path='/verify-success' element={<VerificationSuccessPage />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blog' element={<BlogDetails />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/api-keys' element={<ApiKeysManagement />} />
          {/* <Route path='api-docs' element={<ApiDocumentation />} /> */}
          <Route path='bulk-shortening' element={<BulkShortening />} />
          {/* <Route path='/docs' element={<ApiDocs />} /> */}

          <Route path='*' element={<RedirectToUrl />} />
        </Routes>
      </>
    </Router>
  );
}
