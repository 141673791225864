import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import logo from './../../logo.svg';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';

import { useContext } from 'react';
import { TrimyLinkContext } from '../../../store/TrimyLinkContext';
import { useNavigate } from 'react-router';
import { Loading } from '../../Loading';
import { ErrorTextModal } from '../../popup/ErrorTextModal';
import { SuccessTextModal } from '../../popup/SuccessTextModal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
export function URLGenerateBox() {
  const trimyLinkCtx = useContext(TrimyLinkContext);
  const [inputValue, setInputValue] = useState('');
  const [inputSlug, seInputSlug] = useState('');
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [isEnterUrlDisabled, setIsEnterUrlDisabled] = useState(false);
  const [urlName, setUrlName] = useState('');
  const navigate = useNavigate();
  const [loadingVisible, setLoadingVisible] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      trimyLinkCtx.setGetStartedGenerateButtonText('Generate');
      localStorage.setItem('getStartedGenerateButtonText', 'Generate');
      setIsEnterUrlDisabled(false);
      const tier = localStorage.getItem('tier');
      if ('Standard' === tier || 'Premium' === tier) {
        setIsToggleDisabled(false);
      } else {
        setIsToggleDisabled(true);
      }
    } else {
      setIsEnterUrlDisabled(true);
      trimyLinkCtx.setGetStartedGenerateButtonText('Get Started For Free');
      localStorage.setItem(
        'getStartedGenerateButtonText',
        'Get Started For Free'
      );

      setIsToggleDisabled(true);
    }
  }, [trimyLinkCtx]);

  const [isToggleDisabled, setIsToggleDisabled] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = () => {
    // Copy to clipboard logic...
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000); // Hide after 2 seconds
  };

  async function generateURL() {
    setLoadingVisible(true);
    if (trimyLinkCtx.getStartedGenerateButtonText === 'Get Started For Free') {
      navigate('/signup');
      return;
    }
    const requestId = Date.now().toString();
    try {
      let requestBody;
      if (inputValue !== undefined && inputValue !== '') {
        if (inputSlug !== undefined || inputSlug !== '') {
          requestBody = JSON.stringify({
            url: inputValue,
            slug: inputSlug,
            name: urlName || inputValue,
          });
        } else {
          requestBody = JSON.stringify({
            url: inputValue,
            name: urlName || inputValue,
          });
        }
        const response = await fetch(
          `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/url/shorten`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
              'request-id': requestId,
            },
            body: requestBody,
          }
        );
        const result = await response.json();
        setLoadingVisible(false);
        if (!response.ok) {
          setSuccessText('');
          if (response.status === 400) {
            console.log('1', response.status);
            const error = result.message;
            setErrorText(error);
            setShowError(true);
          } else {
            console.log('2', response.status);
            setErrorText(
              `Ops something went wrong at our end -- Reference Number: ${requestId}`
            );
            setShowError(true);
          }
          setSuccessText('');
        } else {
          setErrorText('');
          setShowError(false);
          setSuccessText(result.shortid);
          setInputValue('');
          setUrlName('');
          seInputSlug('');
        }
      }
    } catch (error) {
      setLoadingVisible(false);
      setErrorText(
        `Ops something went wrong at our end -- Reference Number: ${requestId} `
      );
      setShowError(true);
    }
  }

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <Container fluid className='d-flex h-100'>
          <Col
            xs={12}
            md={12}
            className='align-items-center center justify-content-center custom-width'
          >
            <InputGroup className='mb-3 align-items-center center justify-content-center'>
              <div className='w-100'>
                <Row>
                  <Form.Control
                    placeholder='URL Name /  Ex: My Company Website'
                    aria-label='urlname'
                    value={urlName}
                    disabled={isEnterUrlDisabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setUrlName(e.target.value);
                    }}
                  />
                </Row>
                <Row>
                  <Form.Control
                    className='mt-3'
                    placeholder='Enter Your long URL here '
                    aria-label='Username'
                    value={inputValue}
                    disabled={isEnterUrlDisabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValue(e.target.value);
                    }}
                  />
                </Row>
              </div>
            </InputGroup>

            <Row className='w-100'>
              <Col xs={5} md={5}>
                <OverlayTrigger
                  overlay={<Tooltip id='tooltip'>Coming soon</Tooltip>}
                >
                  <Dropdown className='mb-3 mt-3 w-100'>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-basic'
                      className='w-100'
                      disabled={true}
                    >
                      <span>trimyLink.io</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-100'>
                      <Dropdown.Item href='#/action-1'>
                        trimyLink.io
                      </Dropdown.Item>
                      <Dropdown.Item href='#/action-2'>
                        trimyLink.co
                      </Dropdown.Item>
                      <Dropdown.Item href='#/action-3'>
                        trimyLink.net
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </OverlayTrigger>
              </Col>
              <Col xs={1} md={1}>
                <p className='mb-3 mt-4'> / </p>
              </Col>
              <Col xs={5} md={5}>
                <InputGroup className='mb-3 mt-3 w-100 '>
                  <Form.Control
                    placeholder='/cool-url '
                    aria-label='Username'
                    className='w-100'
                    value={inputSlug}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      seInputSlug(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className='justify-content-center'>
              <Col xs={12} md={12} sm={12}>
                <Button
                  variant='success'
                  className='w-100'
                  onClick={generateURL}
                >
                  {trimyLinkCtx.getStartedGenerateButtonText}
                </Button>
              </Col>
            </Row>
          </Col>
          <ErrorTextModal
            errorText={errorText}
            show={showError}
            onHide={() => setShowError(false)}
          />

          {/* <SuccessTextModal
            successText={successText}
            show={successText.length > 0}
            onHide={() => setSuccessText('')}
          /> */}

          <Modal
            show={successText.length > 0}
            onHide={() => setSuccessText('')}
          >
            <Modal.Header className='d-flex justify-content-between align-items-center'>
              <div></div>
              <Modal.Title className='text-center justify-content-center align-items-center center'>
                <TiTick color='green' size={32} />
              </Modal.Title>
              <div></div>
            </Modal.Header>
            <Modal.Body>
              Your URL has been shortened to
              <Form.Group controlId='formBasicEmail'>
                <div className='input-group'>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip className='tooltip'>
                        {showTooltip ? 'Copied to clipboard' : 'Click to copy'}
                      </Tooltip>
                    }
                  >
                    <span
                      className='input-group-text copy-icon'
                      style={{ cursor: 'pointer' }}
                    >
                      <CopyToClipboard text={successText}>
                        <MdContentCopy onClick={handleCopy} />
                      </CopyToClipboard>
                    </span>
                  </OverlayTrigger>
                  <Form.Control type='text' value={successText} disabled />
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='outline-success'
                onClick={() => setSuccessText('')}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  );
}
