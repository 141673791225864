import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import './../../App.css';
import {
  Modal,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { TrimyLinkContext } from '../../store/TrimyLinkContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCookieBite,
  faEnvelope,
  faFileContract,
  faInfoCircle,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useLogout } from '../auth/Logout';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import logo from './../logo.svg';
import refreshToken from './../auth/RefreshToken';
import { Loading } from '../Loading';
import { GoogleLoginComponent } from '../auth/GoogleLoginComponent';
import { ErrorTextModal } from '../popup/ErrorTextModal';
function Header() {
  const logout = useLogout();
  const trimyLinkCtx = useContext(TrimyLinkContext);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [showSignUp, setShowSignUp] = useState(true);
  const [showLogoutError, setshowLogoutError] = useState(false);
  const [logoutError, setLogoutError] = useState('');

  const handleClose = () => {
    setShow(false);
    setShowSignUp(false);
    setErrorText('');
    setInputEmail('');
    setInputPassword('');
  };
  const handleShow = async () => {
    if (trimyLinkCtx.loginLogOutButtonText === 'Logout') {
      await logout();
      return;
    }
    if (localStorage.getItem('jwtToken')) {
      trimyLinkCtx.setLoginLogOutButtonText('Logout');
      localStorage.setItem('loginLogOutButtonText', 'Logout');
      trimyLinkCtx.setGetStartedGenerateButtonText('Generate');
      localStorage.setItem('getStartedGenerateButtonText', 'Generate');
    } else {
      trimyLinkCtx.setLoginLogOutButtonText('Login');
      localStorage.setItem('loginLogOutButtonText', 'Login');
      trimyLinkCtx.setGetStartedGenerateButtonText('Get Started For Free');
      localStorage.setItem(
        'getStartedGenerateButtonText',
        'Get Started For Free'
      );
      setShow(true);
    }
  };

  async function handleSignUpShow() {
    if (localStorage.getItem('jwtToken')) {
      trimyLinkCtx.setSignUpProfileButtonText('Profile');
      localStorage.setItem('signUpProfileButtonText', 'Profile');
    } else {
      trimyLinkCtx.setSignUpProfileButtonText('Sign Up Free');
      localStorage.setItem('signUpProfileButtonText', 'Sign Up Free');
      setShowSignUp(true);
    }
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('jwtToken')) {
      trimyLinkCtx.setLoginLogOutButtonText('Logout');
      localStorage.setItem('loginLogOutButtonText', 'Logout');
      trimyLinkCtx.setSignUpProfileButtonText('Profile');
      localStorage.setItem('signUpProfileButtonText', 'Profile');

      trimyLinkCtx.setGetStartedGenerateButtonText('Generate');
      localStorage.setItem('getStartedGenerateButtonText', 'Generate');
    } else {
      trimyLinkCtx.setSignUpProfileButtonText('Sign Up Free');
      localStorage.setItem('signUpProfileButtonText', 'Sign Up Free');
      trimyLinkCtx.setLoginLogOutButtonText('Login');
      localStorage.setItem('loginLogOutButtonText', 'Login');
      trimyLinkCtx.setGetStartedGenerateButtonText('Get Started For Free');
      localStorage.setItem(
        'getStartedGenerateButtonText',
        'Get Started For Free'
      );
    }

    const expiresAt = localStorage.getItem('expiresAt');
    if (expiresAt) {
      const expiresInMilliseconds = Number(expiresAt) - Date.now();
      if (expiresInMilliseconds <= 0) {
        console.log('Token expired');
        setShowAlert(true);

        navigate('/');
      } else {
        setTimeout(refreshToken, expiresInMilliseconds - 5000);
      }
    }
  }, [trimyLinkCtx]);

  async function handleLogin(event: any) {
    event.preventDefault();
    setLoadingVisible(true);
    if (inputEmail === '' || inputPassword === '') {
      setErrorText('Please enter email and password');
      setLoadingVisible(false);
      return;
    }
    const requestId = Date.now().toString();

    const response = await fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
        },
        referrerPolicy: 'strict-origin-when-cross-origin',
        credentials: 'include',

        body: JSON.stringify({
          email: inputEmail,
          password: inputPassword,
        }),
      }
    );
    setLoadingVisible(false);
    if (!response.ok) {
      if (response.status === 400) {
        const error = (await response.json()).message;
        setErrorText(error);
      } else {
        setErrorText(
          `Ops something went wrong at our end -- Reference Number: ${requestId}`
        );
      }
      return;
    }
    const data = await response.json();
    setErrorText('');
    localStorage.setItem('jwtToken', data.jwtToken);
    localStorage.setItem('tier', data.tier);
    localStorage.setItem('refreshToken', data.refreshToken);

    const expiresInMilliseconds = data.expiresIn * 1000;
    localStorage.setItem(
      'expiresAt',
      (Date.now() + expiresInMilliseconds).toString()
    );
    setTimeout(refreshToken, expiresInMilliseconds - 5000);
    handleShow();
    handleSignUpShow();
    setShow(false);
    setShowAlert(false);
  }

  const navigate = useNavigate();
  async function handleForgotPassword() {
    const requestId = Date.now().toString();
    try {
      setLoadingVisible(true);
      if (!inputEmail || inputEmail.trim() === '' || inputEmail.length === 0) {
        setErrorText('Please enter email');
        setLoadingVisible(false);
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/forgot-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'request-id': requestId,
          },
          body: JSON.stringify({ email: inputEmail }),
        }
      );
      setLoadingVisible(false);
      if (!response.ok) {
        if (response.status === 400) {
          setShow(false);
          const error = (await response.json()).message;
          setErrorText(error);
          return;
        } else {
          setShow(false);
          setErrorText(
            `Ops something went wrong at our end --- Reference Number: ${requestId}`
          );
        }
        return;
      }
      const data = await response.json();
      console.log('navigating');
      navigate('/forgot-password', { state: { email: inputEmail } });
    } catch (err) {
      setShow(false);
      setErrorText(
        `Ops something went wrong at our end --- Reference Number: ${requestId}`
      );
    }
  }

  return (
    <>
      {loadingVisible ? (
        <>
          <Loading visible={loadingVisible} />
        </>
      ) : (
        <>
          <div style={{ marginTop: '100px' }}>
            {showAlert && (
              <Alert variant='warning'>
                Your session has expired. Please log in again.
              </Alert>
            )}
            {/* ... rest of your component */}
          </div>
          <Navbar bg='light' expand='lg' className='fixed-top'>
            <Navbar.Brand className='logo' onClick={() => navigate('/')}>
              <img src={logo} alt='TrimyLink Logo' style={{ width: '150px' }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse
              id='basic-navbar-nav'
              className='justify-content-center nav-menu-main'
            >
              <Nav className='mr-auto'>
                <Nav.Link
                  onClick={() => navigate('/URLShortenPage')}
                  className='nav-item'
                >
                  URL Shorten
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate('/QRShortenPage')}
                  className='nav-item'
                >
                  QR Codes
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate('/LinkInBioShortenPage')}
                  className='nav-item'
                >
                  Link In Bio
                </Nav.Link>
                <Nav.Link
                  onClick={() => navigate('/pricing')}
                  className='nav-item'
                >
                  Pricing
                </Nav.Link>
                <NavDropdown title='Resources' className='  no-underline'>
                  <NavDropdown.Item
                    as={Link}
                    className='nav-item'
                    to='/about-us'
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className='mx-2' />
                    About Us
                  </NavDropdown.Item>
                  <NavDropdown.Item className='nav-item' as={Link} to='/blogs'>
                    <FontAwesomeIcon icon={faBook} className='mx-2' />
                    Blog
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className='nav-item'
                    as={Link}
                    to='/terms-and-conditions'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={faFileContract} className='mx-2' />
                    Terms and Conditions
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className='nav-item'
                    as={Link}
                    to='/privacy-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={faShieldAlt} className='mx-2' />
                    Privacy Policy
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className='nav-item'
                    as={Link}
                    to='/cookie-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={faCookieBite} className='mx-2' />
                    Cookie Policy
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    className='nav-item'
                    // as={Link}
                    // to='/api-docs'
                    onClick={() =>
                      window.open(
                        '/api-docs.html',
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                    // )}
                  >
                    Developers - API Documentation
                    {/* <OverlayTrigger
                      overlay={<Tooltip id='tooltip'>Coming soon</Tooltip>}
                    >
                      <span className='mx-2'>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                    </OverlayTrigger> */}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <div className='d-flex'>
                <Button
                  variant='link'
                  className='mr-2 btnn-link'
                  onClick={handleShow}
                >
                  {trimyLinkCtx.loginLogOutButtonText}
                </Button>
                {trimyLinkCtx.signUpProfileButtonText !== 'Profile' && (
                  <Button
                    variant='link'
                    className='btnn-link'
                    onClick={() => navigate('/Signup')}
                  >
                    {trimyLinkCtx.signUpProfileButtonText}
                  </Button>
                )}
                {trimyLinkCtx.signUpProfileButtonText === 'Profile' && (
                  <Dropdown>
                    <Dropdown.Toggle variant='link' id='dropdown-basic'>
                      <CgProfile size={30} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='end'>
                      <Dropdown.Item onClick={() => navigate('/Profile')}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          navigate('/Profile', {
                            state: { selectedTab: 'manageSubscription' },
                          })
                        }
                      >
                        Manage Subscription
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>

          <Modal
            show={show}
            onHide={handleClose}
            className='mr-3 ml-3 w-75 w-100'
          >
            <Modal.Header closeButton>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Email address</Form.Label>
                  <div className='input-group'>
                    <span className='input-group-text'>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <Form.Control
                      type='email'
                      className='input-group-addon glyphicon glyphicon-user'
                      placeholder='Enter email'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Group>

                <Form.Group controlId='formBasicPassword'>
                  <Form.Label className='mt-3'>Password</Form.Label>
                  <div className='input-group'>
                    <span className='input-group-text'>
                      <FontAwesomeIcon icon={faKey} />
                    </span>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInputPassword(e.target.value);
                      }}
                    />
                  </div>
                </Form.Group>
                <p className='mt-3 text-danger'>{errorText}</p>

                <GoogleLoginComponent
                  googleLoginComponentProps={{
                    setShow: setShow,
                    setErrorText: setErrorText,
                  }}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row w-100 '>
              <Col sm={4} md={4}>
                <Button
                  variant='outline-danger'
                  type='submit'
                  className='mt-3  w-100  '
                  onClick={handleForgotPassword}
                >
                  Forgot password
                </Button>
              </Col>
              <Col>
                <Button
                  variant='primary'
                  type='submit'
                  className='mt-3  w-100'
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </Col>
            </Modal.Footer>
          </Modal>
        </>
      )}

      <ErrorTextModal
        errorText={errorText}
        show={errorText.length > 0}
        onHide={() => setErrorText('')}
      />
    </>
  );
}

export default Header;
