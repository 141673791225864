import { useEffect } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { MdError } from 'react-icons/md';
import { useNavigate } from 'react-router';

export function ErrorTextModal({
  show,
  onHide,
  errorText,
}: {
  show: boolean;
  onHide: () => void;
  errorText: string;
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (errorText === 'Invalid token') {
      onHide();
      navigate('/');
    }
  }, [errorText, onHide]);
  return (
    <Container className='justify-content-center align-items-center center'>
      <Modal show={show} onHide={onHide}>
        <Modal.Header className='d-flex justify-content-between align-items-center'>
          <div></div>
          <Modal.Title className='text-center justify-content-center align-items-center center'>
            <MdError color='red' size={32} />
          </Modal.Title>
          <div></div>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <p className='text-danger'>
            <b>{errorText}</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-danger' onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
