import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from 'react-bootstrap';
import { Footer } from '../../home-page/Footer';
import Header from '../../home-page/Header';
import { AnalyticsUrlsDropDown } from './AnalyticsUrlsDropDown';
import { useContext, useEffect, useState } from 'react';
import { AnalyticsLinkInBioDropDown } from './AnalyticsLinkInBioDropDown';
import { SummaryChart } from './SummaryChart';
import { DateRangePicker } from 'react-date-range';
import { format, set } from 'date-fns';
import { TopThreeAnalytics } from './TopThreeAnalytics';
import { TopCountriesAnaltyics } from './TopCountriesAnaltyics';
import { TopDevicesAnaltyics } from './TopDevicesAnaltyics';
import { useNavigate } from 'react-router';
import { ErrorTextModal } from '../../popup/ErrorTextModal';
import { TrimyLinkContext } from '../../../store/TrimyLinkContext';
import { addMonths } from 'date-fns';

export function Analytics() {
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [analyticsMonthsLimit, setAnalyticsMonthsLimit] = useState(0);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [analyticsAllowed, setAnalyticsAllowed] = useState(true);
  const navigate = useNavigate();
  const trimyCtx = useContext(TrimyLinkContext);

  useEffect(() => {
    if (!localStorage.getItem('jwtToken')) {
      navigate('/');
    }

    const tier = localStorage.getItem('tier');
    if (tier && tier.toUpperCase() === 'FREE') {
      setAnalyticsAllowed(false);
    }
    if (tier && tier.toUpperCase() === 'BASIC') {
      setAnalyticsMonthsLimit(1);
    }
    if (tier && tier.toUpperCase() === 'STANDARD') {
      setAnalyticsMonthsLimit(5);
    }
    if (tier && tier.toUpperCase() === 'PREMIUM') {
      setAnalyticsMonthsLimit(12);
    }
  }, [navigate]);
  const [selectionRange, setSelectionRange] = useState<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
    key: 'selection',
  });
  function handleSelect(ranges: any) {
    setSelectionRange({
      startDate: new Date(format(ranges.selection.startDate, 'yyyy-MM-dd')),
      endDate: new Date(format(ranges.selection.endDate, 'yyyy-MM-dd')),
      key: 'selection',
    });

    setFrom(format(ranges.selection.startDate, 'yyyy-MM-dd'));

    setTo(format(ranges.selection.endDate, 'yyyy-MM-dd'));
  }

  return (
    <Container>
      <Row className='center align-items-center justify-content-center  '>
        <Col xs={12} sm={6} md={6} lg={3}>
          <AnalyticsUrlsDropDown
            errorText={errorText}
            setErrorText={setErrorText}
            dropDownText='Select Url'
            fetchUrls={true}
            fetchQRs={false}
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <AnalyticsUrlsDropDown
            errorText={errorText}
            setErrorText={setErrorText}
            dropDownText='Select QR Code'
            fetchUrls={false}
            fetchQRs={true}
          />
        </Col>

        <Col xs={12} sm={6} md={6} lg={3}>
          <AnalyticsLinkInBioDropDown
            errorText={errorText}
            setErrorText={setErrorText}
          />
        </Col>
      </Row>

      <Row className='center align-items-center  justify-content-center '>
        <Col sm={12} md={6} lg={3}>
          <Dropdown
            className='mt-3  '
            show={isDropdownOpen}
            onToggle={(isOpen: boolean) => setIsDropdownOpen(isOpen)}
          >
            <DropdownToggle variant='outline-primary'>
              Select Date Range
            </DropdownToggle>
            <DropdownMenu>
              {isDropdownOpen && (
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  inputRanges={[]}
                  minDate={addMonths(new Date(), -analyticsMonthsLimit)}
                  maxDate={new Date()}
                />
              )}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>

      {analyticsAllowed && (
        <>
          <Row className='w-100'>
            <div className='d-flex center align-items-center justify-content-center'>
              <SummaryChart
                errorText={errorText}
                setErrorText={setErrorText}
                from={from}
                to={to}
                setFrom={setFrom}
                setTo={setTo}
                analyticsAllowed={analyticsAllowed}
                setAnalyticsAllowed={setAnalyticsAllowed}
              />
            </div>
          </Row>
          <TopThreeAnalytics
            errorText={errorText}
            setErrorText={setErrorText}
            from={from}
            to={to}
          />

          <Row>
            <Col sm={8} md={8}>
              <TopCountriesAnaltyics
                errorText={errorText}
                setErrorText={setErrorText}
                from={from}
                to={to}
              />
            </Col>
            <Col>
              <TopDevicesAnaltyics
                errorText={errorText}
                setErrorText={setErrorText}
                from={from}
                to={to}
              />
            </Col>
          </Row>
        </>
      )}

      {!analyticsAllowed && (
        <p className='text-danger'>
          Analytics not available for Free Tier,{' '}
          <a
            style={{ color: '#2A4978', cursor: 'pointer' }}
            onClick={(e: any) => {
              e.preventDefault();
              console.log('clicked');
              trimyCtx.setSelectedProfileTab(Date.now().toString());
              navigate('/profile', {
                state: { selectedTab: 'manageSubscription' },
              });
            }}
          >
            <b>Upgrade to paid subscription ?</b>
          </a>
        </p>
      )}
      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
      <Footer />
    </Container>
  );
}
