import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { TiTick } from 'react-icons/ti';
import { MdEmail } from 'react-icons/md';
import logo from './logo.svg';
import emailVerified from './verified-email.jpg';
const VerificationSuccessPage = () => {
  return (
    <Container className='mt-5'>
      <Row className='justify-content-center'>
        <img
          src={emailVerified}
          alt='signup verification success'
          className='border-0 rounded-circle'
          style={{
            maxHeight: '50vh',
            maxWidth: '50vw',
            minWidth: '200px',
            minHeight: '200px',
          }}
        />
      </Row>
      <Row className='justify-content-center'>
        <Col md={6}>
          <Card className='shadow'>
            <Card.Body className='text-center'>
              <i className='fas fa-check-circle text-success fa-5x mb-4'></i>
              <Card.Title className='mb-3'>Verification Successful!</Card.Title>
              <Card.Text>
                Your email has been successfully verified. You can now access
                all features of our platform.
              </Card.Text>
            </Card.Body>
          </Card>
          <Row className='w-100-flex justify-content-center align-items-center center mt-5'>
            <Button
              href='https://trimylink.io'
              className='w-50'
              style={{ backgroundColor: '#1B1A55' }}
            >
              Home Page
            </Button>
          </Row>

          <Row className='w-100-flex justify-content-center align-items-center center mt-5'>
            <img
              src={logo}
              alt='signup verification success'
              className='border-0 rounded-circle'
              style={{
                maxHeight: '50vh',
                maxWidth: '50vw',
                minWidth: '200px',
                minHeight: '200px',
              }}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default VerificationSuccessPage;
