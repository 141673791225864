async function refreshToken() {
  const response = await fetch(
    `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/auth/refresh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'request-id': Date.now().toString(),
        authorization: 'Bearer ' + localStorage.getItem('jwtToken'),
      },
      body: JSON.stringify({
        refreshToken: localStorage.getItem('refreshToken'),
      }),
    }
  );

  if (!response.ok) {
    console.error('Refresh token failed');
    return;
  }

  const data = await response.json();

  localStorage.setItem('jwtToken', data.jwtToken);
  localStorage.setItem('refreshToken', data.refreshToken);
  localStorage.setItem('tier', data.tier);

  const expiresInMilliseconds = data.expiresIn * 1000;
  localStorage.setItem(
    'expiresAt',
    (Date.now() + expiresInMilliseconds).toString()
  );

  setTimeout(refreshToken, expiresInMilliseconds - 5000);
}

export default refreshToken;
