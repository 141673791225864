import {
  faEnvelope,
  faUser,
  faKey,
  faCalendar,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import countries from 'i18n-iso-countries';
import english from '../../countries.json';
import { set } from 'idb-keyval';
import { ErrorTextModal } from '../popup/ErrorTextModal';
import { SuccessTextModal } from '../popup/SuccessTextModal';

countries.registerLocale(english);
const countryList = Object.entries(countries.getNames('en')).map(
  ([value, label]) => ({ label, value })
);

export function AccountInfo() {
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [country, setCountry] = useState<any>();
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [successText, setSuccessText] = useState('');
  const [updated, setUpdated] = useState(false);

  async function fetchAccountInfo() {
    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
      return;
    }
    const requestId = Date.now().toString();
    fetch(`${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'request-id': requestId,
      },
    })
      .then((response) => {
        if (response.ok) {
          setErrorText('');
          setShowError(false);
          return response.json();
        } else {
          if (response.status === 400) {
            const handleResponse = async () => {
              const result = await response.json();
              const error = result?.message;
              setErrorText(error);
              setShowError(true);
            };

            handleResponse();
          } else {
            setErrorText(
              `Ops something went wrong at our end -- Reference Number: ${requestId}`
            );
            setShowError(true);
          }

          setSuccessText('');
        }
      })
      .then((data) => {
        setInputEmail(data.email);
        setFullName(data.name);
        setPhoneNumber(data.mobileNumber);
        setCountry(
          countryList.find(
            (country) =>
              country?.label.toUpperCase() === data.country?.toUpperCase()
          )
        );
        setUsername(data.username);
        setDateOfBirth(data.dateOfBirth);
      })
      .catch((error) => {
        console.log(error);

        setErrorText('Error fetching user info');
        setShowError(true);
        setSuccessText('');
      });
  }
  useEffect(() => {
    fetchAccountInfo();
  }, [updated]);
  function handleUpdateAccountInfo(event: any): void {
    event.preventDefault();

    if (inputPassword !== confirmPassword) {
      setErrorText('Passwords do not match');
      setShowError(true);
      setSuccessText('');
      return;
    }
    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) {
      return;
    }
    let body;

    if (inputPassword && confirmPassword) {
      if (inputPassword.length < 8) {
        setErrorText('Password must be at least 8 characters long');
        setShowError(true);
        setSuccessText('');
        return;
      }
      if (confirmPassword.length < 8) {
        setErrorText('Password must be at least 8 characters long');
        setShowError(true);
        setSuccessText('');
        return;
      }
      if (inputPassword !== confirmPassword) {
        setErrorText('Passwords do not match');
        setShowError(true);
        setSuccessText('');
        return;
      }
      body = {
        name: fullName,
        mobileNumber: phoneNumber,
        country: country?.label,
        dateOfBirth: dateOfBirth,
        email: inputEmail,

        password: inputPassword,
        confirmPassword: confirmPassword,
      };
    } else {
      body = {
        name: fullName,
        mobileNumber: phoneNumber,
        country: country?.label,
        dateOfBirth: dateOfBirth,
        email: inputEmail,
      };
    }
    fetch(
      `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/users/update`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
          'request-id': Date.now().toString(),
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => {
        if (response.ok) {
          setSuccessText('User info updated successfully');
          setErrorText('');
          setShowError(true);
          setInputPassword('');
          setConfirmPassword('');
          setUpdated(true);
        } else {
          setSuccessText('');
          if (response.status === 400) {
            response.json().then((resp) => {
              setErrorText(resp.message);
              setShowError(true);
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setSuccessText('');
        setErrorText('Error updating user info');
        setShowError(true);
      });
  }

  return (
    <>
      <SuccessTextModal
        successText={successText}
        show={successText.length > 0}
        onHide={() => setSuccessText('')}
      />

      <Form
        className='y-flex center align-items-center justify-content-center'
        style={{ width: '100vw' }}
      >
        <Form.Group controlId='formEmailAddress'>
          <Form.Label className='mt-2'>Email address</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <Form.Control
              type='email'
              className='input-group-addon glyphicon glyphicon-user'
              placeholder='Enter email'
              disabled={true}
              value={inputEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputEmail(e.target.value);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formUsername'>
          <Form.Label className='mt-2'>Username</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faUser} />
            </span>
            <Form.Control
              type='email'
              className='input-group-addon glyphicon glyphicon-user'
              placeholder='Username'
              value={username}
              disabled={true}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formBasicPassword'>
          <Form.Label className='mt-2'>Password</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faKey} />
            </span>
            <Form.Control
              type='password'
              placeholder='Password'
              value={inputPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputPassword(e.target.value);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formConfirmPassword'>
          <Form.Label className='mt-2'> Confirm Password</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faKey} />
            </span>
            <Form.Control
              type='password'
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formFullName'>
          <Form.Label className='mt-2'>Fulll Name</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faUser} />
            </span>
            <Form.Control
              type='text'
              placeholder='Full Name'
              value={fullName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFullName(e.target.value);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formDateOfBirth'>
          <Form.Label className='mt-2'>Date of birth</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faCalendar} />
            </span>
            <Form.Control
              type='date'
              placeholder='Date of birth'
              value={dateOfBirth}
            />
          </div>
        </Form.Group>

        <Form.Group controlId='formCountry'>
          <Form.Label className='mt-2'>Country</Form.Label>

          <Select options={countryList} value={country} onChange={setCountry} />
        </Form.Group>

        <Form.Group controlId='formMobileNumber'>
          <Form.Label className='mt-2'>Mobile Number</Form.Label>
          <div className='input-group'>
            <span className='input-group-text'>
              <FontAwesomeIcon icon={faMobile} />
            </span>
            <Form.Control
              type='text'
              placeholder='Mobile Number - ex: +9711234567890'
              value={phoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
        </Form.Group>

        <Row className='align-items-center justify-content-center center'>
          <Button
            variant='primary'
            type='submit'
            className='w-50 mt-3 '
            onClick={handleUpdateAccountInfo}
          >
            Update Info
          </Button>
        </Row>
      </Form>

      <ErrorTextModal
        errorText={errorText}
        show={showError}
        onHide={() => setShowError(false)}
      />
    </>
  );
}
