import { useEffect, useCallback } from 'react'; // Import useCallback
import { useNavigate } from 'react-router-dom';

export function RedirectToUrl() {
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (shortId: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_TRIMY_LINK_BACKEND_HOST}/api/v1/common/redirect${shortId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'request-id': Date.now().toString(),
            },
            referrerPolicy: 'strict-origin-when-cross-origin',
            credentials: 'include',
            cache: 'no-cache',
            redirect: 'manual',
          }
        );
        console.log(response);
        if (!response.ok) {
          navigate('/NotFound');
        } else {
          const data = await response.json();
          if ('orginalUrl' in data) {
            if (
              !data.orginalUrl.startsWith('http://') &&
              !data.orginalUrl.startsWith('https://')
            ) {
              data.orginalUrl = 'http://' + data.orginalUrl;
            }

            window.location.href = data.orginalUrl;
          } else {
            navigate(`/link-in-bio/${shortId.split('/')[1]}`, {
              state: {
                data: data,
              },
            });
          }
        }
      } catch (error) {
        navigate('/NotFound');
      }
    },
    [navigate]
  );

  useEffect(() => {
    const shortId = window.location.pathname;
    console.log('path:', shortId);
    fetchData(shortId);
  }, [fetchData]); // Add navigate as a dependency

  return <></>;
}
